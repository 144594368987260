
import React from "react"
import moment from "moment";
import Message from "../chat/models/Message";
import { logger } from "./Logger";

export const isOnChatPage = () => {
    if (!import.meta.env.SSR) {
        return window.location.pathname.startsWith("/chat") || window.location.pathname.startsWith("/join_group_chat")
    }
    return false;
}

export const capitalizeFirstLetter = (name: string) => {
    if (name) {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }

    return name;
};

export const asyncForEach = async <T>(elems: T[], callback: (elem: T, idx: number, array: Array<T>) => void) => {
    for (let index = 0; index < elems.length; index++) {
        try {
            await callback(elems[index], index, elems);
        } catch (ex) {
            logger.debug("asyncForEach", ex)
        }
    }
}

export const onImageErrorFallback = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.onerror = null
    e.currentTarget.src = "https://res.cloudinary.com/dgerdfai4/image/upload/v1700811299/website/default-avatar-icon.png"
    e.currentTarget.style.background = "#fff"
}

export function isEmpty(val: any) {
    // use cases
    //---------------
    // []        true
    // {}        true
    // null      true
    // undefined true
    // ""        true
    // ''        true
    // 0         false (number)
    // true      false
    // false     false
    // Date      false
    // function  false

    if (val === undefined) {
        return true
    }

    if (
        typeof val == "function" ||
        typeof val == "number" ||
        typeof val == "boolean" ||
        Object.prototype.toString.call(val) === "[object Date]"
    ) {
        return false
    }

    if (val == null || val.length === 0) {
        return true
    }

    if (typeof val == "object") {
        return !!(Object.keys(val).length === 0)
    }

    return false
}

export const copyToClipboard = async function (link: string) {
    try {
        await navigator.clipboard.writeText(link)
        return true
    } catch (e) {
        logger.debug("Copy to clipboard failed! ", e)
        return false
    }
}

export const DefaultAvatar = (avatar: any) => {
    if (!!avatar && avatar.includes('res.cloudinary')) {
        try {
            if (avatar.includes('ic_default_profile.png')) {
                let new_array = avatar.split('upload/w_120,h_120,g_face,c_thumb,f_auto,r_60/v1587531490/ic_default_profile.png');
                return new_array.join('upload/v1700811299/website/default-avatar-icon.png');
            } else {
                return avatar.replace(/\/w_\d+,h_\d+,/, '/');
            }
        } catch (e) {
            logger.debug("DefaultAvatar", e)
            return avatar;
        }
    }
    return avatar;
};

export const getAllowedOrDefaultMimeType = (mime: string) => {
    const allowed = ["video/mp4", "video/mpeg2", "video/m4v", "video/mpeg"]
    if (allowed.includes(mime)) {
        return mime
    }
    return "video/mp4"
}

export const ToggleState = (callback: React.Dispatch<React.SetStateAction<boolean>>) => {
    return function () {
        callback((prev) => !prev)
    }
}

export const getComputedMessageTime = (message: Message) => {
    const date = moment(message.timestamp).calendar(null, {
        // when the date is closer, specify custom values
        lastWeek: "Do MMM YYYY",
        lastDay: "[Yesterday]",
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "Do MMM YYYY",
        // when the date is further away, use from-now functionality
        sameElse: function () {
            return "Do MMM YYYY"
        },
    })
    return date
}

export const isSameDay = (currentMessage: Message, diffMessage: Message) => {
    return moment(currentMessage.timestamp).isSame(diffMessage.timestamp, 'day')
}

export const isSameTime = (currentMsg: Message, nextMsg: Message) => {
    return moment(currentMsg.timestamp).isSame(nextMsg.timestamp, 'minute')
}

export const getLastMessageTimeStamp = (timehandle: number) => {
    let lastMessageTs = moment(timehandle / 1000)
    const currentDate = moment();
    if (lastMessageTs.isSame(currentDate, 'day')) {
        return lastMessageTs.format("hh:mm A");
    }
    if (lastMessageTs.isSame(currentDate, 'year')) {
        // Within a year
        return lastMessageTs.format("MMM, DD");
    } else {
        // Over 1 year
        return lastMessageTs.format("MMM, Do YYYY");
    }
}

export const eventify = function (callback: any, payload: any) {
    return function () {
        return callback(payload)
    }
}

export const redirectToLogin = () => {
    const redirectUrl = '/login';
    const redirectQueryString = `?redirect_url=${window.location.pathname + window.location.search}`;
    const fullRedirectUrl = redirectUrl + redirectQueryString;
    window.location.replace(fullRedirectUrl)
}

export const removeLeadingSpaces = (text: string) => {
    return text.trimStart();
};

export const promisifiedTimeout = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const isServerSideRendered = () => {
    return import.meta.env.SSR;
}

export const isBannerHiddenLocally = () => {
    const lcValue = localStorage.getItem('isLaunchBannerHidden');
    return lcValue === "true"
}
