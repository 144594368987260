import MessageState from "../enums/MessageState";
import MessageType from "../enums/MessageType";
import ReferenceType from "../enums/ReferenceType";
import UserRole from "../enums/UserRole";

export class Mention {
    user: string = "";
    role = UserRole.UNKNOWN;

    start: number = 0
    end: number = 0
}

export class Sender {
    uuid: string = "";

    name: string = "";
    avatar: string = 'https://images.myyogateacher.com/profile-pic.png';

    role: UserRole = UserRole.UNKNOWN;
}

export class Media {

    size: number = 0;
    fileName: string = "noname.file";
    mimeType: string = "application/octet-stream";

    thumbKey: string = ""
    dataKey: string = ""
    duration: number = 0

    validUpto: number = 0
    url: string = ""
}

export class Reference {

    type = ReferenceType.NONE

    id: string = ""
    text: string = ""
    media: Array<Media>
    mentions: Array<Mention>
    timeHandle = 0
    sender: Sender
}

export class Flags {
    isCare: boolean
    lastMessageTimeHandle: number
    isAutomated: boolean
}

export class Pinner {
    uuid: string = "";

    name: string = "";
    avatar: string = 'https://images.myyogateacher.com/profile-pic.png';

    role: UserRole = UserRole.UNKNOWN;

    timestamp: number = 0 //pinned at millis
}

export class Location {
    latitude: number = -1
    longitude: number = -1
    isValid: boolean = false
}

export default class Message {
    id: string = "";

    from: string = ""
    to: string = ""

    channel: string = "";

    type: MessageType = MessageType.NONE

    state: MessageState = MessageState.NONE

    text: string = ""
    media = new Array<Media>()
    link: string = ""
    contact: string = "" //contact card
    location = new Location()

    isDeleted: boolean = false

    timestamp: number = 0

    timeHandle: number = 0

    errorText: string = ""

    errorDesc: string = ""

    sender = new Sender()
    receiver = new Sender()
    pinner = new Pinner()

    mentions = new Array<Mention>()

    reference = new Reference()

    flags = new Flags()

    isPublicPinned: boolean = false
    isPrivatePinned: boolean = false
}