import Packet from "../enums/Packet"
import User from "../models/User"
import QueuedPacket from "../packets/QueuedPacket"
import RawPacket from "../packets/RawPacket"
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient";
import { CallbackHandler } from "../callbacks/handler";
import { RecoType } from "../enums/RecoType";
import { logger } from "../../utils/Logger";
import UserRole from "../enums/UserRole";
import { RecoUser } from "../packets/RecoItem";

export default class RecoHandler {

    static shared = new RecoHandler()

    private constructor() { }

    private frequentlyContacted = new Map<string, RecoUser>()
    private youMightKnow = new Map<string, RecoUser>()
    private youMightKnowLoaded = false

    clear = () => {
        this.frequentlyContacted.clear()
        this.youMightKnow.clear()
        this.youMightKnowLoaded = false
    }

    fetchMemberSuggestionFrequentlyContacted = () => {
        if (this.frequentlyContacted.size > 0) {
            CallbackHandler.shared.callOnRecoData(this.frequentlyContacted, false, RecoType.FrequentlyContacted)
            return
        }

        let pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.RECO
        pkt[Packet.Keys.SUB_TYPE] = Packet.Reco.Types.LIST_FREQUENTLY_CONTACTED
        pkt[Packet.Keys.ID] = uuidV4();

        return ChatClient.shared.sendPacket(pkt)
    }

    fetchMemberSuggestionYouMightKnow = () => {
        if (this.youMightKnow.size > 0) {
            CallbackHandler.shared.callOnRecoData(this.youMightKnow, this.youMightKnowLoaded, RecoType.YouMightKnow)
            return
        }

        let pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.RECO
        pkt[Packet.Keys.SUB_TYPE] = Packet.Reco.Types.LIST_YOU_MIGHT_KNOW
        pkt[Packet.Keys.ID] = uuidV4();

        return ChatClient.shared.sendPacket(pkt)
    }

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.Reco.Types.LIST_FREQUENTLY_CONTACTED:
                {
                    let items: Array<RawPacket> = res[Packet.Common.USER_LIST];
                    items.forEach((item) => {
                        let it = {
                            uuid: item[Packet.Common.USER] as string,
                            name: item[Packet.Account.Keys.NAME] as string,
                            avatar: item[Packet.Account.Keys.AVATAR] as string,
                            role: item[Packet.Account.Keys.ROLE] as UserRole,
                            type: RecoType.FrequentlyContacted
                        }

                        this.frequentlyContacted.set(it.uuid, it)
                    });

                    CallbackHandler.shared.callOnRecoData(this.frequentlyContacted, false, RecoType.FrequentlyContacted)
                }
                break;
            case Packet.Reco.Types.LIST_YOU_MIGHT_KNOW:
                {
                    let items: Array<RawPacket> = res[Packet.Common.USER_LIST];
                    let more: boolean = res[Packet.Query.MORE] === Packet.Values.TRUE

                    items.forEach((item) => {
                        let reco: RecoUser = {
                            uuid: item[Packet.Common.USER] as string,
                            name: item[Packet.Account.Keys.NAME] as string,
                            avatar: item[Packet.Account.Keys.AVATAR] as string,
                            role: item[Packet.Account.Keys.ROLE] as UserRole,
                            type: item[Packet.Reco.Keys.HINT] as RecoType // you might know sub result type
                        }

                        this.youMightKnow.set(reco.uuid, reco)
                    });

                    this.youMightKnowLoaded = more

                    CallbackHandler.shared.callOnRecoData(this.youMightKnow, more, RecoType.YouMightKnow);
                }
                break;
            default:
                {
                    logger.leaveBreadcrumb("RecoHandler: unrecognized", {
                        type: res
                    })
                    logger.error("RecoHandler: unrecognized");
                }
                break;
        }
    }
}