import GroupRole from "../enums/GroupRole";
import UserRole from "../enums/UserRole";

export default class Member {
    uuid: string = ""
    name: string = "User";
    avatar: string = 'https://images.myyogateacher.com/profile-pic.png';
    urole: UserRole = UserRole.UNKNOWN;
    grole: GroupRole = GroupRole.NONE;
    timestamp: number = 0; // last update time millis
    isInvited: number = 0
}
