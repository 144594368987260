enum MessageType {
    NONE = "none",
    P2P = "p2p",
    GROUP = "muc",
    GROUP_CREATE = "grp+",
    JOIN_GROUP = "muc+",
    LEAVE_GROUP = "muc-",
    MUC_MEMBER_ROLE_CHANGED = "muc:mr",
    MUC_MEMBER_INVITE = "muc:i+"
}

export default MessageType;