import { logger } from "../../utils/Logger"
import Packet from "../enums/Packet"
import QueuedPacket from "../packets/QueuedPacket"
import RawPacket from "../packets/RawPacket"
import { v4 as uuidV4 } from 'uuid';
import Message, { Media, Sender } from "../models/Message"
import ChatClient from "./ChatClient"
import MessageDBOps from "../database/MessageDBOps";
import Constants from "../config/Constants";
import MessageUtils from "../utils/MessageUtils";

export type SuspectedMessageInfo = {
    sent_by: Sender,
    flagged_by: Sender
    flagged_at: number
    reason: string
    message: Message
}

export default class SuspectHandler {

    static shared = new SuspectHandler()

    private constructor() { }

    fetchDetails = async (message: Message): Promise<SuspectedMessageInfo> => {

        let pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.SUSPECT
        pkt[Packet.Keys.SUB_TYPE] = Packet.Suspect.Types.GET
        pkt[Packet.Common.CHANNEL] = message.channel;
        pkt[Packet.Message.Keys.MSG_TYPE] = message.type
        pkt[Packet.Suspect.Keys.ID] = message.id
        pkt[Packet.Keys.ID] = uuidV4()

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }

            pkt.message = message
        });
    }

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.Suspect.Types.GET:
                {
                    const result: SuspectedMessageInfo = {
                        sent_by: new Sender(),
                        flagged_by: new Sender(),
                        flagged_at: res[Packet.Suspect.Keys.TIMESTAMP],
                        reason: res[Packet.Suspect.Keys.REASON],
                        message: queued!.packet!.message
                    }

                    result.sent_by.uuid = res[Packet.Suspect.Keys.SENT_BY_PROFILE][Packet.Common.USER]
                    result.sent_by.name = res[Packet.Suspect.Keys.SENT_BY_PROFILE][Packet.Account.Keys.NAME]
                    result.sent_by.avatar = res[Packet.Suspect.Keys.SENT_BY_PROFILE][Packet.Account.Keys.AVATAR]
                    result.sent_by.role = res[Packet.Suspect.Keys.SENT_BY_PROFILE][Packet.Account.Keys.ROLE]

                    result.flagged_by.uuid = res[Packet.Suspect.Keys.FLAGGED_BY_PROFILE][Packet.Common.USER]
                    result.flagged_by.name = res[Packet.Suspect.Keys.FLAGGED_BY_PROFILE][Packet.Account.Keys.NAME]
                    result.flagged_by.avatar = res[Packet.Suspect.Keys.FLAGGED_BY_PROFILE][Packet.Account.Keys.AVATAR]
                    result.flagged_by.role = res[Packet.Suspect.Keys.FLAGGED_BY_PROFILE][Packet.Account.Keys.ROLE]

                    const data = res[Packet.Message.Keys.DATA];

                    const text = data[Packet.Message.Data.TEXT];
                    if (typeof text === "string") {
                        result.message.text = text;
                    }

                    result.message.link = data[Packet.Message.Data.LINK] || ''
                    result.message.contact = data[Packet.Message.Data.CONTACT] || ''

                    const location = data[Packet.Message.Data.LOCATION]
                    if (location) {
                        result.message.location.latitude = location[Packet.Message.Location.LATITUDE]
                        result.message.location.longitude = location[Packet.Message.Location.LONGITUDE]

                        if (result.message.location.latitude !== -1 && result.message.location.longitude !== -1) {
                            result.message.location.isValid = true
                        }
                    }

                    const medias = data[Packet.Message.Data.MEDIA];

                    if (Array.isArray(medias)) {
                        const kv = {
                            size: Packet.Message.Media.SIZE,
                            mimeType: Packet.Message.Media.MIME,
                            fileName: Packet.Message.Media.NAME,
                            thumbKey: Packet.Message.Media.THUMB_KEY,
                            duration: Packet.Message.Media.DURATION,
                            dataKey: Packet.Message.Media.DATA_KEY,
                        }

                        result.message.media = medias.map((item: RawPacket) => {
                            let media = new Media()

                            media.validUpto = Date.now() + Constants.OneDayMillis

                            Object.entries(kv).forEach(([k, v]) => {
                                if (v in item) {
                                    media[k] = item[v]
                                }
                            })

                            if (MessageUtils.isMediaVideo(media)) {
                                media.url = item[Packet.Message.Media.THUMB_URL] || item[Packet.Message.Media.DATA_URL]
                            } else {
                                media.url = item[Packet.Message.Media.DATA_URL]
                            }

                            return media;
                        });
                    }

                    queued?.resolve?.(result)
                }
                break
            default:
                {
                    logger.leaveBreadcrumb("SuspectHandler: unrecognized", {
                        type: res
                    })
                    logger.error("SuspectHandler: unrecognized");
                }
                break
        }
    }

}