import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import CommonHeader from '../../util_components/common_header';
import { get_api_url } from '../../../utils/urls';
import { get_api, post_api } from '../../../redux/api_funcs';
import { client_domain, magic_link_campaigns_redirect_path } from '../../../constants';
import CustomSingleSelect from '../../util_components/custom_single_select';
import CustomDatePicker from '../../util_components/custom_date_picker';
import CustomInput from '../../util_components/custom_input';
import NoData from '../../../../public/static/images/no-data.jpg';

const MagicLinkCampaigns = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [magicLinksData, setMagicLinksData] = useState([]);
  const [filterExpiryDate, setFilterExpiryDate] = useState(null);
  const [filterRedirectPath, setFilterRedirectPath] = useState('');
  const [filterDomain, setFilterDomain] = useState('');
  const [page, setPage] = useState(1);
  const [magicLinkFile, setMagicLinkFile] = useState(null);
  const [redirectPath, setRedirectPath] = useState('');
  const [prefix, setPrefix] = useState('');
  const [clientDomain, setClientDomain] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isPreviousPage, setIsPreviousPage] = useState(false);
  const [isNextPage, setIsNextPage] = useState(false);
  const [sampleFileUrl, setSampleFileUrl] = useState('');
  const [otherRedirectPath, setOtherRedirectPath] = useState('');
  const [filterOtherRedirectPath, setFilterOtherRedirectPath] = useState('');
  const [showOtherRedirectPath, setShowOtherRedirectPath] = useState(false);

  useEffect(() => {
    checkSubmitButton();
  }, [magicLinkFile, redirectPath, prefix, clientDomain, expiryDate, otherRedirectPath]);

  useEffect(() => {
    loadData();
  }, [page, filterRedirectPath, filterDomain, filterExpiryDate]);
  // set state for model open and close
  const toggleVisibility = () => {
    setMagicLinkFile(null);
    setRedirectPath('');
    setPrefix('');
    setClientDomain('');
    setExpiryDate(null);
    setOtherRedirectPath('');
    setShowOtherRedirectPath(false);
    setShowModal((prevShowModal) => !prevShowModal);
  };
  const closeModel = () => {
    setFilterExpiryDate(null);
    setFilterRedirectPath('');
    setFilterDomain('');
    setMagicLinkFile(null);
    setRedirectPath('');
    setPrefix('');
    setClientDomain('');
    setExpiryDate(null);
    setIsButtonDisabled(true);
    setSampleFileUrl('');
    setOtherRedirectPath('');
    setFilterOtherRedirectPath('');
    setShowOtherRedirectPath(false);
    setShowModal(false);
    setPage(1);
  };
  // set state for filter and retrieve the data
  const onFilterCommonChange = (e) => {
    const key = e.target.name;
    const {value} = e.target;
    switch (key) {
      case 'filterRedirectPath':
        setFilterRedirectPath(value);
        setFilterOtherRedirectPath('');
        break;
      case 'filterDomain':
        setFilterDomain(value);
        break;
      case 'filterExpiryDate':
        setExpiryDate(value);
        break;
      case 'filterOtherRedirectPath':
        setFilterOtherRedirectPath(value);
        break;
      default:
        break;
    }
    setPage(1);
  };
  // check the submit button enabled if all input are filled
  const checkSubmitButton = () => {
    if (magicLinkFile && redirectPath && clientDomain && expiryDate) {
      if ((redirectPath === 'others' && otherRedirectPath) || redirectPath !== 'others') {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else {
      setIsButtonDisabled(true);
    }
  };
  // set the input values
  const onRedirectPathChanges = (e) => {
    const {value} = e.target;
    setRedirectPath(value);
    setOtherRedirectPath('');
    if (value === 'others') {
      setShowOtherRedirectPath(true);
    } else {
      setShowOtherRedirectPath(false);
    }
  };

  const onFileInputChange = (e) => {
    setMagicLinkFile(e.target.files[0]);
    checkSubmitButton();
  };
  // set the date values
  const handle_date_change = (name, val) => {
    switch (name) {
      case 'expiryDate':
        setExpiryDate(val);
        checkSubmitButton();
        break;
      case 'filterExpiryDate':
        setFilterExpiryDate(val);
        setPage(1);
        break;
      default:
        break;
    }
  };
  // handle the form
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const finalExpiryDate = moment(expiryDate).format('YYYY-MM-DD');
    formData.append('magic_link_file', magicLinkFile);
    formData.append('redirect_path', redirectPath);
    formData.append('prefix', prefix);
    formData.append('client_domain', clientDomain);
    formData.append('expiry_date', finalExpiryDate);
    formData.append('other_redirect_path', otherRedirectPath);
    const url = get_api_url('/v2/cms_user/magic_link_campaigns', null, true);
    setLoading(true);
    post_api(url, formData, true, 'formdata')
      .then(() => {
        closeModel();
        loadData();
      })
      .catch((e) => {
        console.log(e);
        let err_message;
        try {
          err_message = e?.response?.data?.message;
        } catch (err) {
          err_message = 'Some error occurred. Please contact care team.';
        }
        setLoading(false);
      });
  };

  const handlePagination = (pageNo) => {
    if (pageNo > 0 && pageNo <= totalPages) {
      setPage(pageNo);
    }
  };
  // loading the all magic_link_campaigns based on filters
  const loadData = () => {
    setLoading(true);
    let apiUrl = `/v2/cms_user/get_magic_link_campaigns?page=${page}`;
    if (filterExpiryDate) {
      apiUrl += `&expiry_date=${filterExpiryDate}`;
    }
    if (filterRedirectPath) {
      apiUrl += `&redirect_path=${filterRedirectPath}`;
    }
    if (filterDomain) {
      apiUrl += `&client_domain=${filterDomain}`;
    }
    if (filterOtherRedirectPath) {
      apiUrl += `&other_redirect_path=${filterOtherRedirectPath}`;
    }
    const url = get_api_url(apiUrl, null, true);
    get_api(url, true)
      .then((res) => {
        const data = res?.data?.data;
        let prePageDisable = false;
        let nextPage = false;
        if (page > 1) {
          prePageDisable = true;
        }
        if (page < data?.total_pages) {
          nextPage = true;
        }
        console.log(res);
        setMagicLinksData(data?.items);
        setTotalItems(data?.total_items);
        setSampleFileUrl(data?.sample_magic_link_campaign_file);
        setIsPreviousPage(prePageDisable);
        setIsNextPage(nextPage);
        setLoading(false);
        setTotalPages(data?.total_pages);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  // render the table header
  const renderTableHeader = () => (
      <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
        <div className="flex items-center flex-1 space-x-4">
          <h5>
            <span className="text-black-500">All MagicLink Campaigns</span>
          </h5>
        </div>
        <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
          <a href={sampleFileUrl}>
            <button className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium bg-transparent text-blue-700 hover:bg-blue-700 hover:text-white">
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="black"
                viewBox="0 0 25 25"
              >
                <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
              </svg>
              Download File Format
            </button>
          </a>

          <button
            className="block text-white bg-blue-700 hover:bg-transparent hover:text-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            type="button"
            onClick={toggleVisibility}
          >
            Import
          </button>
        </div>
      </div>
    );

  // render the filter section
  const renderFilter = () => (
      <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
        <div className="flex items-center flex-1 space-x-4">
          <h5>
            <span className="text-gray-500">Filters</span>
          </h5>
        </div>
        <form onSubmit={loadData}>
          <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
            <CustomDatePicker
              label="Expiry Date"
              value={filterExpiryDate}
              name="filterExpiryDate"
              handleChange={(val) => handle_date_change('filterExpiryDate', val)}
            />
            <CustomSingleSelect
              label="Redirect Path"
              options={magic_link_campaigns_redirect_path}
              name="filterRedirectPath"
              onChange={onFilterCommonChange}
              value={filterRedirectPath}
            />
            {filterRedirectPath === 'others' ? (
              <CustomInput
                label="Other Redirect Path"
                value={filterOtherRedirectPath}
                name="filterOtherRedirectPath"
                onChange={onFilterCommonChange}
                onSubmit={loadData}
              />
            ) : null}

            <CustomSingleSelect
              label="Client Domain"
              options={client_domain}
              name="filterDomain"
              onChange={onFilterCommonChange}
              value={filterDomain}
            />
            <button className="hidden" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    );

  const renderOtherRedirectPathTextField = () => (
      <div className="col-span-2 sm:col-span-1">
        <CustomInput
          label="*Other Redirect Path"
          onChange={(e) => setOtherRedirectPath(e.target.value)}
          name="other_redirect_path"
          value={otherRedirectPath}
          // style={{ width: '260px' }}
        />
      </div>
    );

  // render the modal
  const renderCreateMagicLinkModel = () => (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/* content */}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/* header */}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h4 className="px-3 py-1 text-2xl font-semibold">Import File</h4>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-1xl font-semibold"
                  onClick={toggleVisibility}
                >
                  <svg
                    className="w-4 h-4 text-gray-800"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>
              {/* body */}
              <form onSubmit={handleFormSubmit}>
                <div className="relative p-6 flex-auto">
                  <div className="grid gap-4 mb-4 grid-cols-2">
                    <div className="col-span-2">
                      <label
                        htmlFor="magic_link_file"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        *File
                      </label>
                      <input
                        type="file"
                        name="magic_link_file"
                        id="magicLinkFile"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        placeholder="Select CSV file"
                        required
                        accept=".csv"
                        onChange={onFileInputChange}
                      />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <CustomSingleSelect
                        label="*Client Domain"
                        options={client_domain}
                        name="client_domain"
                        onChange={(e) => setClientDomain(e.target.value)}
                        value={clientDomain}
                      />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <CustomSingleSelect
                        label="*Redirect Path"
                        options={magic_link_campaigns_redirect_path}
                        name="redirect_path"
                        onChange={onRedirectPathChanges}
                        value={redirectPath}
                      />
                    </div>
                    {showOtherRedirectPath ? renderOtherRedirectPathTextField() : null}
                    <div className="col-span-2 sm:col-span-1">
                      <CustomDatePicker
                        label="*Expiry Date"
                        name="expiry_date"
                        value={expiryDate}
                        handleChange={(val) => handle_date_change('expiryDate', val)}
                        minDate={new Date()}
                        maxDate={moment(new Date()).add(3, 'months').toDate()}
                      />
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <label
                        htmlFor="prefix"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Prefix
                      </label>
                      <input
                        type="text"
                        name="prefix"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        placeholder="prefix"
                        maxLength={4}
                        onChange={(e) => setPrefix(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border border-red-500 hover:text-white hover:bg-red-500"
                    type="button"
                    onClick={toggleVisibility}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className={`text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:bg-transparent hover:text-blue-700 ${
                      isButtonDisabled ? 'pointer-events-none opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    <svg
                      className="me-1 -ms-1 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                        clipRule="evenodd"
                       />
                    </svg>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>
    );

  // render the magic_link_campaigns table
  const renderMagicLinkCampaignTable = () => (
      <div>
        {magicLinksData.length > 0 ? (
          <table className="w-full text-sm text-left">
            <thead className="text-xs uppercase">
              <tr>
                <th scope="col" className="px-4 py-3">
                  File Name
                </th>
                <th scope="col" className="px-4 py-3">
                  Expiry Date
                </th>
                <th scope="col" className="px-4 py-3">
                  Redirect Path
                </th>
                <th scope="col" className="px-4 py-3">
                  Client Domain
                </th>
                <th scope="col" className="px-4 py-3">
                  Magic Link
                </th>
              </tr>
            </thead>
            <tbody>{magicLinksData.map(renderSingleMagicLinkCampaign)}</tbody>
          </table>
        ) : (
          <div className="flex items-center justify-center">
            <img src={NoData} className="w-1/2 h-1/2" />
          </div>
        )}
      </div>
    );

  // render the single magic_link_campaign
  const renderSingleMagicLinkCampaign = (magicLink) => (
      <tr className="border-b">
        <td scope="col" className="px-4 py-2 font-medium">
          <a href={magicLink.input_file_signed_url}>{magicLink.input_file_name}</a>
        </td>
        <td scope="col" className="px-4 py-2">
          <span
            className={`text-primary-800 text-xs font-medium px-2 py-0.5 rounded ${
              moment(magicLink.expiry_at).endOf('day').isBefore(moment()) ? 'text-red-500' : ''
            }`}
          >
            {magicLink.expiry_at}
          </span>
        </td>
        <td scope="col" className="px-4 py-2 font-medium">
          {magicLink.redirect_path}
        </td>
        <td className="px-4 py-2 font-medium">{magicLink.client_domain}</td>
        <td scope="col" className="px-4 py-2 font-medium whitespace-nowrap items-center">
          <a href={magicLink.magic_link_file_signed_url}>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 16 18"
            >
              <path
                stroke={
                  moment(magicLink.expiry_at).endOf('day').isBefore(moment()) ? 'red' : 'green'
                }
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
              />
            </svg>
          </a>
        </td>
      </tr>
    );

  const renderPagination = () => (
      <div className="flex items-center justify-center mt-4">
        <button
          className={`px-3 py-2 mr-2 font-medium leading-5 rounded-md ${
            isPreviousPage
              ? 'bg-transparent text-black hover:text-white hover:bg-blue-700 cursor-pointer'
              : 'bg-transparent text-black cursor-not-allowed'
          }`}
          onClick={() => handlePagination(page - 1)}
          disabled={!isPreviousPage}
        >
          Previous
        </button>
        <span className="px-3 py-2 font-medium leading-5 text-black-700">
          Page {page} of {totalPages}
        </span>
        <button
          className={`px-3 py-2 ml-2 font-medium leading-5 rounded-md ${
            isNextPage
              ? 'bg-transparent text-black hover:text-white hover:bg-blue-700 cursor-pointer'
              : 'bg-transparent text-black cursor-not-allowed'
          }`}
          onClick={() => handlePagination(page + 1)}
          disabled={!isNextPage}
        >
          Next
        </button>
      </div>
    );

  // render list of magic_link_campaigns
  const renderListOfMagicLinkCampaigns = () => (
      <div>
        <div>
          <section>
            <div>
              <div className="relative overflow-hidden sm:rounded-lg">
                {renderTableHeader()}
                {renderFilter()}
                <div className="overflow-x-auto">{renderMagicLinkCampaignTable()}</div>
                {totalItems > 0 ? renderPagination() : null}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  const renderMagicLinkCampaigns = () => {
    const title = 'Magic Link Campaigns';
    return (
      <CommonHeader loading={loading} title={title} meta_title="Magic Link Campaigns">
        {showModal ? renderCreateMagicLinkModel() : null}
        {!loading ? renderListOfMagicLinkCampaigns() : null}
      </CommonHeader>
    );
  };
  return renderMagicLinkCampaigns();
};

export default MagicLinkCampaigns;
