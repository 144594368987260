import { all, put, takeLatest, call, delay, takeEvery } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import { post_api } from '../api_funcs';
import { get_api_url } from '../../utils/urls';

export function* getAllStudents() {
  try {
    const url = get_api_url('get_all_students_v1');
    const result = yield call(post_api, url, null, true);
    yield put(actions.set_loading_variable('get_all_students_status', 'loading'));
    yield put(actions.filter_students('all_students', [...result.data.students]));
    yield put(actions.set_loading_variable('get_all_students_status', 'success'));
    yield delay(108000000);
    yield put(actions.get_all_students());
  } catch (error) {
    yield put(actions.set_loading_variable('get_all_students_status', 'fail'));
    console.log(error);
    if (!!error.response && error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}

export function* searchStudents(action) {
  try {
    console.log('in search saga');
    yield put(actions.set_loading_variable('student_search_status', 'loading'));
    const url = get_api_url('search_students_including_queue');
    const result = yield call(post_api, url, action.payload, true);
    yield put(actions.set_students_variable('search_students_list', [...result.data.students]));
    yield put(actions.set_loading_variable('student_search_status', 'success'));
  } catch (e) {
    yield put(actions.set_loading_variable('student_search_status', 'fail'));
  }
}

export function* cudMembershipPause(action) {
  let url = '';
  switch (action.payload.type) {
    case 'create':
      try {
        yield put(actions.set_loading_variable('membership_status', 'loading'));
        url = get_api_url('create_membership_pause');
        yield call(post_api, url, action.payload.payload, true);
        yield put(actions.set_loading_variable('membership_status', 'success'));
        yield put(actions.set_notification_variable(true, 'success', 'Membership paused'));
      } catch (e) {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Membership pause failed';
        }
        yield put(actions.set_loading_variable('membership_status', 'fail'));
        yield put(actions.set_loading_variable('show_pause_error', true));
        yield put(actions.set_loading_variable('pause_error_response', err_message));
      }
      break;
    case 'update':
      try {
        yield put(actions.set_loading_variable('membership_status', 'loading'));
        url = get_api_url('update_membership_pause');
        yield call(post_api, url, action.payload.payload, true);
        yield put(actions.set_loading_variable('membership_status', 'success'));
        yield put(actions.set_notification_variable(true, 'success', 'Membership pause updated'));
      } catch (e) {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Membership pause failed';
        }
        yield put(actions.set_loading_variable('membership_status', 'fail'));
        yield put(actions.set_notification_variable(true, 'error', err_message));
      }
      break;
    case 'delete':
      try {
        yield put(actions.set_loading_variable('membership_status', 'loading'));
        url = get_api_url('delete_membership_pause');
        yield call(post_api, url, action.payload.payload, true);
        yield put(actions.set_loading_variable('membership_status', 'success'));
        yield put(actions.set_notification_variable(true, 'success', 'Membership pause cancelled'));
      } catch {
        yield put(actions.set_loading_variable('membership_status', 'fail'));
        yield put(
          actions.set_notification_variable(true, 'error', 'Membership pause cancel failed')
        );
      }
      break;
    default:
      break;
  }
  try {
  } catch {}
}

export function* cudMembership(action) {
  let url = '';
  switch (action.payload.type) {
    case 'upgrade':
      try {
        yield put(actions.set_loading_variable('membership_status', 'loading'));
        url = get_api_url('create_membership_pause');
        yield call(post_api, url, action.payload.payload, true);
        yield put(actions.set_loading_variable('membership_status', 'success'));
        yield put(actions.set_notification_variable(true, 'success', 'Membership paused'));
      } catch (e) {
        let err_message;
        try {
          err_message = e.response.data.message;
        } catch (err) {
          err_message = 'Membership pause failed';
        }
        yield put(actions.set_loading_variable('membership_status', 'fail'));
        yield put(actions.set_notification_variable(true, 'error', err_message));
      }
      break;
    case 'downgrade':
      try {
        yield put(actions.set_loading_variable('membership_status', 'loading'));
        url = get_api_url('update_membership_pause');
        yield call(post_api, url, action.payload.payload, true);
        yield put(actions.set_loading_variable('membership_status', 'success'));
        yield put(actions.set_notification_variable(true, 'success', 'Membership pause updated'));
      } catch {
        yield put(actions.set_loading_variable('membership_status', 'fail'));
        yield put(
          actions.set_notification_variable(true, 'error', 'Membership pause update failed')
        );
      }
      break;
    case 'delete':
      try {
        yield put(actions.set_loading_variable('membership_status', 'loading'));
        url = get_api_url('membership_cancel');
        yield call(post_api, url, action.payload.payload, true);
        yield put(actions.set_loading_variable('membership_status', 'success'));
        yield put(actions.set_notification_variable(true, 'success', 'Membership cancelled'));
      } catch (error) {
        let errorMsg = 'Membership cancel failed';
        yield put(actions.set_loading_variable('membership_status', 'fail'));
        yield put(actions.set_notification_variable(true, 'error', errorMsg));
        errorMsg = error.response.data.reason;
        yield put(actions.set_loading_variable('show_member_error_popup', true));
        yield put(actions.set_loading_variable('membership_error_response', errorMsg));
      }
      break;
    case 'resume':
      try {
        yield put(actions.set_loading_variable('membership_status', 'loading'));
        url = get_api_url('resume_membership');
        yield call(post_api, url, action.payload.payload, true);
        yield put(actions.set_loading_variable('membership_status', 'success'));
        yield put(actions.set_notification_variable(true, 'success', 'Membership resumed'));
      } catch {
        yield put(actions.set_loading_variable('membership_status', 'fail'));
        yield put(actions.set_notification_variable(true, 'error', 'Membership resume failed'));
      }
      break;
    default:
      break;
  }
  try {
  } catch {}
}
export default function* chatSaga() {
  yield all([
    takeLatest(t.get_all_students, getAllStudents),
    takeEvery(t.cud_membership_pause, cudMembershipPause),
    takeEvery(t.cud_membership, cudMembership),
    takeEvery(t.search_students, searchStudents)
  ]);
}
