export default class FileUtils {

    static removeExtension = (filename: string) => {
        return (
            filename.substring(0, filename.lastIndexOf('.')) || filename
        );
    }

    static getVideoCover = (file: File, seekTo = 1):
        Promise<{
            blob: Blob | null,
            duration: number,
        }> => {
        return new Promise((resolve, reject) => {
            // load the file to a video player
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', URL.createObjectURL(file));
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                reject("error when loading video file", ex);
            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 200);

                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {

                    const MAX_WIDTH = 300;
                    const MAX_HEIGHT = 300;

                    var width = videoPlayer.videoWidth;
                    var height = videoPlayer.videoHeight;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height = height * (MAX_WIDTH / width);
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width = width * (MAX_HEIGHT / height);
                            height = MAX_HEIGHT;
                        }
                    }

                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = width
                    canvas.height = height
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d")!
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    // return the canvas image as a blob
                    ctx.canvas.toBlob(
                        blob => {
                            resolve({
                                blob: blob,
                                duration: videoPlayer.duration
                            });
                        },
                        "image/jpeg",
                        0.75 /* quality */
                    );
                });
            });
        });
    }
}