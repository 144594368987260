import Packet from "../enums/Packet"
import PresenceType from "../enums/PresenceType"
import QueuedPacket from "../packets/QueuedPacket"
import RawPacket from "../packets/RawPacket"
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient";
import moment from "moment";
import { CallbackHandler } from "../callbacks/handler";

export default class PresenceHandler {
    static shared = new PresenceHandler()

    private constructor() { }

    private lastSeen = new Map<string, string>()

    clear = () => {
        this.lastSeen.clear()
    }

    getLastSeen = (user: string): Promise<{ status: string }> => {
        let pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.PRESENCE
        pkt[Packet.Common.USER] = user;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (this.lastSeen.has(user)) {
                return resolve({ status: this.lastSeen.get(user)! })
            }

            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        const user = res[Packet.Common.USER];

        const state = res[Packet.Presence.STATE] as PresenceType;
        // const lastSeen = (res[Packet.Presence.LAST_SEEN] as number) * 1000

        let status = ""
        if (state === PresenceType.ONLINE) {
            status = "Online"
        } else {
            // let delta = Math.round((Date.now() - lastSeen) / (60 * 1000)) // minutes

            // if (delta <= 10) { //will uncomment last seen logic in v3
            //     status = `Last Seen ${delta} minutes ago`
            // } else if (lastSeen > 0) {
            //     let m = moment(new Date(lastSeen))

            //     if (m.isSame(new Date(), 'day')) {
            //         status = m.format("hh:mm A")
            //     } else if (m.isSame(new Date(), 'year')) {
            //         status = m.format("MMM, DD")
            //     } else {
            //         status = m.format("MMM, Do YYYY")
            //     }

            //     status = `Last Seen ${status}`
            // } else {
            // status = "Offline"
            // }
        }

        this.lastSeen.set(user, status)

        queued?.resolve?.({ status: status })

        CallbackHandler.shared.callOnPresenceReceived(user, status)
    }
}