import Packet from "../enums/Packet"
import { PushSettingItem } from "../packets/PushSettingItem"
import { db } from "./MytChatDB"
import ChatService from "../../external/ChatService";

export default class ChannelDBOps {

    static shared = new ChannelDBOps()

    private constructor() { }

    getChannel = (uuid: string) => {
        return db.channel.get(uuid)
    }

    updateChannelPushSettings = async (settings: Array<PushSettingItem>) => {
        const channelIds = settings.filter((item) => !item.push && !!item.channel).map((x) => x.channel)
        const userIds = settings.filter((item) => !item.push && !!item.user).map((x) => x.user)

        await db.channel.filter((channel) => {
            return !channel.isMuted && (channelIds.includes(channel.uuid) || userIds.includes(channel.otherUserId))
        })
            .modify({ isMuted: true })

        await db.channel.filter((channel) => {
            return channel.isMuted && !channelIds.includes(channel.uuid) && !userIds.includes(channel.otherUserId)
        })
            .modify({ isMuted: false })
    }

    removeChannel = async (uuid: string) => {
        await db.channel.delete(uuid)
    }

    triggerUnreadUpdate = async () => {
        let unread = 0

        await db.channel
            .where("unread")
            .above(0)
            .or("isInviteActionPending").equals(Packet.Values.TRUE)
            .each(x => {
                if (x.isInviteActionPending === Packet.Values.TRUE) {
                    unread = unread + 1
                } else {
                    unread = unread + x.unread
                }
            })

        ChatService.updateUnreadCount(unread)
    }
}