import Packet from "../enums/Packet"
import QueuedPacket from "../packets/QueuedPacket"
import RawPacket from "../packets/RawPacket"
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient";
import Config from "../models/Config";
import ChannelDBOps from "../database/ChannelDBOps";
import { PushSettingItem } from "../packets/PushSettingItem";
import { logger } from "../../utils/Logger";
import Channel from "../models/Channel";
import ChannelType from "../enums/ChannelType";
import { db } from "../database/MytChatDB";
import { PushMsgType } from "../enums/PushMsgType";
import Constants from "../config/Constants";
import PubSub from 'pubsub-js'

const DeviceTypeFirebase = 4 //for browsers

export type NotiToggledData = {
    channelId: string
    isMuted: boolean
}

export default class PushHandler {

    static shared = new PushHandler()

    private constructor() { }

    registerPush = (token: string) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.PUSH
        pkt[Packet.Keys.SUB_TYPE] = Packet.Push.Types.ADD_REG
        pkt[Packet.Push.Keys.TOKEN_TYPE] = DeviceTypeFirebase;
        pkt[Packet.Push.Keys.TOKEN] = token;
        pkt[Packet.Keys.ID] = uuidV4()

        ChatClient.shared.sendPacket(pkt, undefined, true);
    };

    unregisterPush = (token: string): Promise<{ success: boolean }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.PUSH
        pkt[Packet.Keys.SUB_TYPE] = Packet.Push.Types.DEL_REG;
        pkt[Packet.Push.Keys.TOKEN_TYPE] = DeviceTypeFirebase;
        pkt[Packet.Push.Keys.TOKEN] = token;
        pkt[Packet.Keys.ID] = uuidV4()

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    getPushSetting = (): Promise<{ settings: PushSettingItem[] }> => {
        return new Promise((resolve, reject) => {
            const pkt: RawPacket = {};
            pkt[Packet.Keys.TYPE] = Packet.Types.PUSH
            pkt[Packet.Keys.SUB_TYPE] = Packet.Push.Types.PUSH_SETTING_GET;
            pkt[Packet.Keys.ID] = uuidV4()

            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    //Set push setting

    private channelTypeToPushMsgType(type: ChannelType): PushMsgType {
        switch (type) {
            case ChannelType.P2P:
                return PushMsgType.P2P;
            case ChannelType.GROUP:
                return PushMsgType.GROUP;
            default:
                return PushMsgType.NONE;
        }
    }

    private channelToPushSettingItem = (channel: Channel): PushSettingItem => {
        return {
            type: this.channelTypeToPushMsgType(channel.type),
            push: false,
            sound: true,
            channel: channel.uuid,
            user: channel.otherUserId,
            timestamp: Date.now(),
        };
    }

    setPushSetting = async (channel: Channel, isMuted: boolean) => {
        const items = new Array<PushSettingItem>();
        if (isMuted) {
            items.push(this.channelToPushSettingItem(channel));
        }

        await db.channel
            .filter((dbItem) => dbItem.isMuted && dbItem.uuid !== channel.uuid)
            .each((dbItem) => {
                items.push(this.channelToPushSettingItem(dbItem));
            })

        await this.setPushSettingInternal(items)

        const payload: NotiToggledData = {
            channelId: channel.uuid,
            isMuted: isMuted
        }

        PubSub.publish(Constants.PubSubNotiToggled, payload)
    }

    private setPushSettingInternal = (settings: Array<PushSettingItem>) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.PUSH
        pkt[Packet.Keys.SUB_TYPE] = Packet.Push.Types.PUSH_SETTING_SET;
        pkt[Packet.Keys.ID] = uuidV4()

        pkt[Packet.Push.Keys.ListSetting] =
            settings.filter(item => {
                return !item.push
            }).map(item => {
                const ob = {};
                ob[Packet.Push.Keys.PUSH_TYPE] = item.type;
                ob[Packet.Push.Keys.PUSH] = item.push ? Packet.Push.Values.ON : Packet.Push.Values.OFF

                if (typeof item.sound == "string") {
                    ob[Packet.Push.Keys.SOUND] = item.sound;
                } else if (!!item.sound) {
                    ob[Packet.Push.Keys.SOUND] = Packet.Push.Values.ON
                } else {
                    ob[Packet.Push.Keys.SOUND] = Packet.Push.Values.OFF;
                }

                if (item.type === PushMsgType.GROUP) {
                    ob[Packet.Common.CHANNEL] = item.channel;
                } else {
                    ob[Packet.Common.USER] = item.user;
                }

                if (!!item.text) {
                    ob[Packet.Push.Keys.TEXT] = item.text;
                }

                ob[Packet.Push.Keys.TIMESTAMP] = item.timestamp;

                return ob;
            });

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.Push.Types.ADD_REG:
                {
                    const token: string = queued!.packet[Packet.Push.Keys.TOKEN];
                    Config.shared.markRegistered(token);
                }
                break;
            case Packet.Push.Types.PUSH_SETTING_GET:
                {
                    const settings = this.parsePushSettings(res);
                    await ChannelDBOps.shared.updateChannelPushSettings(settings);
                    queued?.resolve?.({ settings: settings });
                }
                break;
            case Packet.Push.Types.PUSH_SETTING_SET:
                {
                    const settings = this.parsePushSettings(res);
                    await ChannelDBOps.shared.updateChannelPushSettings(settings);
                    queued?.resolve?.({ success: true, settings: settings });
                }
                break;
            case Packet.Push.Types.DEL_REG:
                {
                    queued?.resolve?.({ success: true });
                }
                break;
            default:
                {
                    logger.error("NotificationHandler: unrecognized pkt", res);
                }
                break;
        }
    };

    parsePushSettings = (pkt: RawPacket): Array<PushSettingItem> => {
        const settings = pkt[Packet.Push.Keys.ListSetting] || [];

        return settings.map((rawItem: RawPacket) => {

            const sound = rawItem[Packet.Push.Keys.SOUND] || Packet.Push.Values.OFF;
            const isEnabled = rawItem[Packet.Push.Keys.PUSH] === Packet.Push.Values.ON;

            const setting: PushSettingItem = {
                type: rawItem[Packet.Push.Keys.PUSH_TYPE],
                push: isEnabled,
                sound: sound,
                text: rawItem[Packet.Push.Keys.TEXT] || null,
                user: rawItem[Packet.Common.USER] || null,
                channel: rawItem[Packet.Common.CHANNEL] || null,
                timestamp: rawItem[Packet.Push.Keys.TIMESTAMP] || 0
            };

            return setting;
        });
    };
}