enum ServerMessageState {
    BLOCKED = -2,
    UNKNOWN = -1,
    DELETED = 0,
    VALID = 1,
    OFFLINE = 2,
    DELIVERED = 3,
    READ = 4
}

export default ServerMessageState;