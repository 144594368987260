import { getAuthToken } from "../../network/cookie"
import urls from "../../network/urls"
import { logger } from "../../utils/Logger"
import Packet from "../enums/Packet"
import QueuedPacket from "../packets/QueuedPacket"
import RawPacket from "../packets/RawPacket"
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient"
import Message, { Media } from "../models/Message"
import MessageDBOps from "../database/MessageDBOps"
import { CallbackHandler } from "../callbacks/handler"
import MessageUtils from "../utils/MessageUtils"
import { S3ACL } from "../enums/S3ACL"

export type S3FetchData = {
    fileKey: string,
    url: string
}

export type QueryCache = {
    message: string,
    dataKey: string
    url?: string
}

type Resolve = (data: S3FetchData) => void

export default class S3Handler {

    static shared = new S3Handler()

    private constructor() { }

    private queries = new Map<string, QueryCache>()
    private promises = new Map<string, Resolve[]>()

    clear = () => {

    }

    clearUrlCache = () => {
        logger.leaveBreadcrumb("S3Handler->clearUrlCache")
        this.queries.clear()
        this.promises.clear()
    }

    loadMediaFileUrl = async (
        message: Message,
        media: Media,
        forceLoad: boolean = false,
    ): Promise<S3FetchData | undefined> => {
        let fileKey = media.dataKey

        if (MessageUtils.isVideoThumbAvailable(media)) {
            fileKey = media.thumbKey
        }

        if (!fileKey.includes("/chat/")) {
            fileKey = "/production/chat/" + fileKey;
        }

        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.S3
        pkt[Packet.Keys.SUB_TYPE] = Packet.S3.Types.SIGNED_URL;
        pkt[Packet.S3.Keys.FILE_KEY] = fileKey;
        pkt[Packet.Keys.ID] = uuidV4()

        if (MessageUtils.isUrlValid(media)) {
            return {
                fileKey: fileKey,
                url: media.url
            }
        }

        const queryItem = this.queries.get(fileKey)
        if (queryItem && queryItem.url) {
            return {
                fileKey: fileKey,
                url: queryItem.url
            }
        }

        return new Promise<S3FetchData>((resolve, reject) => {
            if (queryItem) {
                const promises = this.promises.get(fileKey) || []

                promises.push(resolve)

                this.promises.set(fileKey, promises)
                return
            }

            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }

            this.queries.set(fileKey, {
                message: message.id,
                dataKey: media.dataKey,
            })
        });
    };

    loadFileUrl = (media: Media): Promise<{
        fileKey: string,
        url: string,
    }> => {
        let fileKey = media.dataKey

        if (!fileKey.includes("/chat/")) {
            fileKey = "/production/chat/" + fileKey;
        }

        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.S3
        pkt[Packet.Keys.SUB_TYPE] = Packet.S3.Types.SIGNED_URL;
        pkt[Packet.S3.Keys.FILE_KEY] = fileKey;
        pkt[Packet.Keys.ID] = uuidV4()

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    uploadFileToS3 = async (
        file: File,
        acl: S3ACL,
        mediaIdx: number = 0,
        message?: Message,
    ): Promise<{
        fileKey: string,
        url: string,
    }> => {

        const token = getAuthToken()

        const formdata = new FormData();
        formdata.append("file", file, file.name);

        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();

            request.open("POST", urls.file_upload);
            request.setRequestHeader("Authorization", "Bearer " + token);
            request.setRequestHeader("x-myt-chat-file-size", file.size.toString());
            request.setRequestHeader("x-aws-file-acl", acl);

            request.upload.onprogress = (evt) => {
                if (evt.lengthComputable && message) {
                    const percentComplete = (evt.loaded / evt.total);
                    CallbackHandler.shared.callOnProgressUpdated(message, mediaIdx, percentComplete)
                }
            }

            request.addEventListener('loadend', (e) => {
                const isSuccess = ((request.status >= 200) && (request.status <= 299));

                const result: RawPacket = JSON.parse(request.responseText);

                if (isSuccess) {
                    const fileKey = result[Packet.S3.Keys.S3_PATH] as string;
                    const url = result[Packet.S3.Keys.URL] as string

                    if (message) {
                        CallbackHandler.shared.callOnProgressUpdated(message, mediaIdx, 1)
                    }

                    resolve({
                        fileKey: fileKey,
                        url: url,
                    });
                } else {
                    reject();
                }
            });

            request.send(formdata);
        });
    };

    shareMediaPublicly = async (media: Media): Promise<{
        link: string
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.S3
        pkt[Packet.Keys.SUB_TYPE] = Packet.S3.Types.SHARE_FILE;
        pkt[Packet.S3.Keys.FILE_KEY] = media.dataKey;
        pkt[Packet.Keys.ID] = uuidV4()

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.S3.Types.SIGNED_URL:
                {
                    const fileKey = queued!.packet[Packet.S3.Keys.FILE_KEY]
                    const url = (res[Packet.S3.Keys.URL] as string).replace(":443", "")

                    const result: S3FetchData = {
                        fileKey: fileKey,
                        url: url
                    }

                    queued!.resolve?.(result)

                    const item = this.queries.get(fileKey)

                    if (item) {
                        logger.debug(`media: db: id: ${item.message} key: ${item.dataKey}`)
                        await MessageDBOps.shared.updateMediaLink(item.message, item.dataKey, url)
                    }

                    const promises = this.promises.get(fileKey) || []
                    promises.forEach(resolve => resolve(result))

                    this.promises.delete(fileKey)
                }
                break;
            case Packet.S3.Types.SHARE_FILE:
                {
                    const sharedKey = res[Packet.S3.Keys.SHARED_KEY]

                    queued?.resolve?.({
                        link: `https://${window.location.hostname}/chat/shared/${sharedKey}`
                    })
                }
                break
            default:
                {
                    logger.leaveBreadcrumb("S3Handler: unrecognized", {
                        type: res
                    })
                    logger.error("S3Handler: unrecognized");
                }
                break;
        }
    }
}