const DisconnectReason = {
    SHUTDOWN: 3001,
    REPLACED: 3002,
    QUEUE_OVERFLOW: 3003,
    CLIENT_REQUESTED: 3004,
    BANNED: 3005,
    OTHER: 3010
};

export const InvalidDisconnections = [
    DisconnectReason.BANNED,
    DisconnectReason.REPLACED,
    DisconnectReason.CLIENT_REQUESTED
];

export default DisconnectReason;