import Packet from "../enums/Packet"
import QueuedPacket from "../packets/QueuedPacket"
import RawPacket from "../packets/RawPacket"
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient";
import ResponseCode from "../enums/ResponseCode";
import RelayItem from "../packets/RelayItem";
import { CallbackHandler } from "../callbacks/handler";
import Config from "../models/Config";
import { logger } from "../../utils/Logger";
import { RelayGroupInviteItem } from "../callbacks/relay";
import GroupDBOps from "../database/GroupDBOps";
import UserRole from "../enums/UserRole";
import { Sender } from "../models/Message";
import { asyncForEach } from "../../utils/general";

export default class RelayHandler {

    static shared = new RelayHandler()

    private relayAllFetched = false
    private relayItems = new Map<string, RelayItem>()

    clear = () => {
        this.relayAllFetched = false
        this.relayItems.clear()
    }

    markRelayAllRead = () => {
        const queryHandle = Date.now() * 1000;
        return this.sendRelayEventRead(false, queryHandle)
    };

    // markRelayReadUpto = (timeHandle: number) => {
    //     return this.sendRelayEventRead(false, timeHandle).catch(this.printError);
    // };

    // markRelayEventRead = (eventID: string) => {
    //     return this.sendRelayEventRead(eventID, false).catch(this.printError);
    // };

    private sendRelayEventRead = (eventID: string | boolean, timeHandle: number | false) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.RELAY
        pkt[Packet.Keys.SUB_TYPE] = Packet.Relay.Types.RECEIPTS
        pkt[Packet.Keys.ID] = uuidV4();

        if (!!eventID) {
            pkt[Packet.Relay.Keys.READ_ID] = eventID;
        } else if (!!timeHandle) {
            pkt[Packet.Query.HANDLE] = timeHandle;
        }

        return ChatClient.shared.sendPacket(pkt, undefined, true)
    }

    fetchRelayPackets = (queryHandle?: number, eventType?: string, subType?: string) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.RELAY
        pkt[Packet.Keys.SUB_TYPE] = Packet.Relay.Types.GET
        pkt[Packet.Relay.Keys.FILTER_EVENT_TYPE] = eventType || "";
        pkt[Packet.Relay.Keys.FILTER_SUB_TYPE] = subType || "";
        pkt[Packet.Keys.ID] = uuidV4();
        pkt[Packet.Query.HANDLE] = queryHandle || Config.shared.cached().relaySyncTimeHandle;
        pkt[Packet.Query.LIMIT] = 100

        ChatClient.shared.sendPacket(pkt)
    };

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.Relay.Types.GET:
                {
                    const items: Array<RawPacket> = res[Packet.Message.Keys.MESSAGES];
                    const hasNext = res[Packet.Query.MORE] == Packet.Values.TRUE
                    const queryHandle = res[Packet.Query.HANDLE];

                    const packets = items.map(this.parseRelayPacket);

                    Config.shared.updateRelaySyncTimeHandle(queryHandle);

                    this.updateRelayItems(packets);
                    this.relayAllFetched = hasNext

                    await this.callOnRelayData()
                }
                break;
            case Packet.Relay.Types.RECEIPTS:
                {
                    if (!!queued) {
                        const success = res[Packet.Keys.RESPONSE_CODE] == ResponseCode.OK
                        const count = res[Packet.Relay.Keys.COUNT];

                        const eventID = queued.packet[Packet.Relay.Keys.READ_ID] as string || false;
                        const timeHandle = queued.packet[Packet.Query.HANDLE] as number || false;

                        if (success) {
                            if (!!eventID) {
                                this.updateRelayEventRead(eventID);
                            }
                            else if (!!timeHandle) {
                                this.updateRelayRangeRead(timeHandle);
                            }
                        }
                        queued.resolve?.({ success: success, count: count });
                    } else {
                        const eventID = res[Packet.Relay.Keys.READ_ID] || false;
                        const timeHandle = res[Packet.Query.HANDLE] || false;
                        if (!!eventID) {
                            this.updateRelayEventRead(eventID);
                        }
                        else if (!!timeHandle) {
                            this.updateRelayRangeRead(timeHandle);
                        }
                    }

                    await this.callOnRelayData()
                }
                break;
            case Packet.Relay.Types.EVENT:
                {
                    const parsed = this.parseRelayPacket(res);
                    this.updateRelayItems([parsed]);

                    await this.callOnRelayData()
                }
                break;
            default:
                {
                    logger.leaveBreadcrumb("RelayHandler: unrecognized", {
                        type: res
                    })
                    logger.error("RelayHandler: unrecognized");
                }
                break;
        }
    }

    updateRelayItems = (items: Array<RelayItem>) => {
        items.forEach((item) => {
            if (item.deleted) {
                this.relayItems.delete(item.relay);
            } else {
                this.relayItems.set(item.relay, item);
            }
        });
    }

    callOnRelayData = async () => {
        const array: RelayItem[] = []

        await asyncForEach(Array.from(this.relayItems.values()), async (item) => {
            const processed = await this.processRelayEvent(item)
            if (!processed) {
                array.push(item)
            }
        })

        if (array.length === 0) {
            return
        }

        //sort items
        array.sort((a: RelayItem, b: RelayItem) => {
            const ar = a.unread ? 1 : 0;
            const br = b.unread ? 1 : 0;

            return br - ar || b.timestamp - a.timestamp
        });

        //call event
        CallbackHandler.shared.callOnRelayData(array, this.relayAllFetched)
    }

    updateRelayEventRead = (relay: string) => {
        const item = this.relayItems.get(relay);

        if (item) {
            item.unread = false;
        }
    }

    updateRelayRangeRead = (queryHandle: number) => {
        this.relayItems.forEach((item: RelayItem, relay: string) => {
            if (queryHandle > item.timeHandle) {
                item.unread = false;
            }
        });
    }

    private parseRelayPacket = (pkt: RawPacket): RelayItem => {
        const parsed = {
            from: pkt[Packet.Common.FROM],
            id: pkt[Packet.Keys.ID],
            timestamp: pkt[Packet.Common.TIMESTAMP],
            relay: pkt[Packet.Relay.Keys.RELAY_ID],
            deleted: false,
            data: {},
            timeHandle: -1,
            unread: false
        };

        if (pkt[Packet.Relay.Keys.RELAY_ID] == Packet.Relay.State.DELETED) {
            parsed.deleted = true;
        } else {
            parsed.data = pkt[Packet.Message.Keys.DATA];
            parsed.timeHandle = pkt[Packet.Message.Keys.TIME_HANDLE];
            parsed.unread = (pkt[Packet.Relay.Keys.STATUS] == Packet.Relay.State.VALID);
        }

        return parsed;
    };

    private processRelayEvent = async (item: RelayItem) => {
        switch (item.data.type) {
            case Packet.Types.GROUP:
                {
                    // if (item.data.subtype === Packet.Group.Types.INVITE_ADD) {

                    //     const invitedByRaw = item.data[Packet.Group.Keys.PARENT_USER_PROF];

                    //     const invitedBy = new Sender()
                    //     invitedBy.uuid = invitedByRaw[Packet.Common.USER]
                    //     invitedBy.name = invitedByRaw[Packet.Account.Keys.NAME]
                    //     invitedBy.avatar = invitedByRaw[Packet.Account.Keys.AVATAR]
                    //     invitedBy.role = invitedByRaw[Packet.Account.Keys.ROLE]

                    //     const parsed: RelayGroupInviteItem = {
                    //         text: item.data[Packet.Message.Data.TEXT],
                    //         channel: item.data[Packet.Common.CHANNEL],
                    //         timestamp: item.data[Packet.Common.TIMESTAMP],
                    //         name: item.data[Packet.Group.Keys.NAME],
                    //         avatar: item.data[Packet.Group.Keys.AVATAR],
                    //         grouptype: item.data[Packet.Group.Keys.SGROUP_TYPE],
                    //         role: item.data[Packet.Group.Keys.ROLE],
                    //         invitedBy: invitedBy
                    //     }

                    //     await GroupDBOps.shared.addInvitedGroup(parsed)

                    //     return true
                    // }
                    return true
                }
                break
            default:
                // {
                //     logger.debug("RelayHandler: unrecognized pkt", item);
                // }
                return false
        }
    }
}