export enum RecoType {
    FrequentlyContacted = 'fq',
    YouMightKnow = 'ymk', //cumulative
    YouMightKnowGroupMember = 'gm', //student
    YouMightKnowChildRef = 'cref',  //student
    YouMightKnowParentRef = 'pref', //student
    YouMightKnowTeacherStudent = 't',   // can be teacher or student
    YouMightKnowGroupClass = 'gc',  //students
    YouMightKnowWorkshop = 'ws',    //students
    YouMightKnowYogaWithFriend = 'ywf'  //student
}