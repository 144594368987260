import { logger } from "../../utils/Logger";
import ChatEventType from "../enums/ChatEventType";
import Packet from "../enums/Packet";
import Channel from "../models/Channel";
import Config from "../models/Config";
import RawPacket from "../packets/RawPacket";
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient";
import QueuedPacket from "../packets/QueuedPacket";
import { CallbackHandler } from "../callbacks/handler";
import ResponseCode from "../enums/ResponseCode";
import MessageDBOps from "../database/MessageDBOps";
import Message, { Media, Mention, Pinner, Sender } from "../models/Message";
import MessageState from "../enums/MessageState";
import ServerMessageState from "../enums/ServerMessageState";
import ReferenceType from "../enums/ReferenceType";
import UserDBOps from "../database/UserDBOps";
import User from "../models/User";
import MessageType from "../enums/MessageType";
import UserRole from "../enums/UserRole";
import ChannelUtils from "../utils/ChannelUtils";
import ChannelType from "../enums/ChannelType";
import PinType from "../enums/PinType";
import PinHandler from "./PinHandler";
import { ReceiptItem } from "../packets/ReceiptItem";
import Member from "../models/Member";
import GroupDBOps, { InvitedGroupInfo } from "../database/GroupDBOps";
import { db } from "../database/MytChatDB";
import S3Handler from "./S3Handler";
import Constants from "../config/Constants";
import MessageUtils from "../utils/MessageUtils";
import GroupRole from "../enums/GroupRole";
import FileUtils from "../../utils/FileUtils";
import { S3ACL } from "../enums/S3ACL";
import ChannelDBOps from "../database/ChannelDBOps";
import moment from "moment";
import PubSub from 'pubsub-js'
import { asyncForEach } from "../../utils/general";

export type WireMessage = {
    message: Message
    user?: User,
    group?: {
        member: Member,
        channel: string,
    },
    unread: number,
    isFriend: boolean,
    pinner?: Pinner
}

type RecieptInfo = {
    delivery: number, read: number
}

export default class MessageHandler {

    static shared = new MessageHandler()

    private constructor() { }

    private latestArchive = new Map<string, boolean>()
    private latestReceiptInfo = new Map<string, RecieptInfo>()
    private filesCache = new Map<string, File>()

    clear = () => {
        this.latestArchive.clear()
        this.latestReceiptInfo.clear()
    }

    removeChannel = async (channelId: string) => {
        Array.from(this.latestReceiptInfo.keys()).filter(x => x.includes(channelId)).forEach(x => {
            this.latestReceiptInfo.delete(x)
        })

        Array.from(this.latestArchive.keys()).filter(x => x.includes(channelId)).forEach(x => {
            this.latestArchive.delete(x)
        })
    }

    addReceiptInfo = (channelId: string, info: RecieptInfo) => {
        const cached = this.latestReceiptInfo.get(channelId)

        if (cached) {
            info.delivery = Math.max(cached.delivery, info.delivery)
            info.read = Math.max(cached.read, info.read)
        }

        this.latestReceiptInfo.set(channelId, info)
    }

    markChannelOpened = (channelItem: Channel) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.MESSAGE
        pkt[Packet.Keys.SUB_TYPE] = Packet.Message.Types.EVENT
        pkt[Packet.Message.Keys.EVENT] = ChatEventType.CHANNEL_OPENED;
        pkt[Packet.Common.CHANNEL] = channelItem.uuid;
        pkt[Packet.Channel.Type] = channelItem.type;
        pkt[Packet.Keys.ID] = uuidV4();

        if (Config.shared.isCare()) {
            logger.leaveBreadcrumb("Care user not allowed to use this api");
            return;
        }

        ChatClient.shared.sendPacket(pkt);
    };

    sendDeliveryReceipt = (message: Message) => {
        return this.sendReceipt(
            message.channel,
            message.type,
            Packet.Message.Receipt.DELIVERY_ID,
            message.timeHandle,
            message.from)
    }

    sendReadReceipt = (message: Message) => {
        return this.sendReceipt(
            message.channel,
            message.type,
            Packet.Message.Receipt.READ_ID,
            message.timeHandle,
            message.from)
    };

    sendDeleteReceipt = (message: Message, reason?: string) => {
        return this.sendReceipt(
            message.channel,
            message.type,
            Packet.Message.Receipt.DELETE_ID,
            message.timeHandle,
            MessageUtils.isMyMessage(message) ? message.to : message.from,
            message.id,
            reason)
    };

    private sendReceipt = (
        channelID: string,
        messageType: MessageType,
        receiptKeyName: string,
        timeHandle: number,
        toUser?: string,
        messageId?: string,
        deleteReason?: string,
    ) => {

        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.MESSAGE
        pkt[Packet.Keys.SUB_TYPE] = Packet.Message.Types.RECEIPTS
        pkt[Packet.Common.CHANNEL] = channelID;

        if (toUser) {
            pkt[Packet.Common.TO] = toUser
        }

        pkt[Packet.Message.Keys.TIME_HANDLE] = timeHandle;
        pkt[receiptKeyName] = messageId || Packet.Values.ALL

        pkt[Packet.Message.Keys.MSG_TYPE] = messageType;
        pkt[Packet.Keys.ID] = uuidV4();

        if (deleteReason) {
            pkt[Packet.Message.Receipt.DELETE_REASON] = deleteReason;
        }

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    getMessageSnapShot = (messageType: MessageType) => {
        if (Config.shared.isCare()) {
            return logger.debug("api not supported for care user");
        }

        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.MESSAGE
        pkt[Packet.Keys.SUB_TYPE] = Packet.Message.Types.SNAPSHOT
        pkt[Packet.Message.Keys.MSG_TYPE] = messageType
        pkt[Packet.Message.Keys.TIME_HANDLE] = Config.shared.lastMessageTimeHandle(messageType)
        pkt[Packet.Keys.ID] = uuidV4()

        return ChatClient.shared.sendPacket(pkt, undefined, true);
    };

    sendChatState = (channel: Channel, isTyping: boolean) => {
        const pkt: RawPacket = {};

        pkt[Packet.Keys.TYPE] = Packet.Types.MESSAGE
        pkt[Packet.Keys.SUB_TYPE] = isTyping ? Packet.Message.Types.CHAT_STATE_TYPING : Packet.Message.Types.CHAT_STATE_CLEAR

        if (channel.type === ChannelType.GROUP) {
            pkt[Packet.Common.CHANNEL] = channel.uuid;
        } else {
            pkt[Packet.Common.TO] = channel.otherUserId;
        }

        pkt[Packet.Message.Keys.MSG_TYPE] = ChannelUtils.channelTypeToMessageType(channel.type);
        pkt[Packet.Keys.ID] = uuidV4()

        return ChatClient.shared.sendPacket(pkt);
    };

    fetchLatestReceipt = (channel: Channel) => {
        if (this.latestReceiptInfo.has(channel.uuid)) {
            return this.fetchArchiveMessages(channel, true)
        }

        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.MESSAGE
        pkt[Packet.Keys.SUB_TYPE] = Packet.Message.Types.RECEIPT_INFO
        pkt[Packet.Common.CHANNEL] = channel.uuid;
        pkt[Packet.Common.USER] = channel.otherUserId;
        pkt[Packet.Message.Keys.MSG_TYPE] = ChannelUtils.channelTypeToMessageType(channel.type);
        pkt[Packet.Keys.ID] = uuidV4();

        return ChatClient.shared.sendPacket(pkt, undefined, true)
    }

    private fetchPreviousChannelMessages = (channel: Channel) => {
        logger.leaveBreadcrumb("archive: previous: latest: false, ", {
            uuid: channel.uuid,
            name: channel.name,
            first: channel.firstMessageTimeHandle
        })

        return this.fetchArchiveMessages(channel)
    }

    private onArchivefetchCompleted = (channel: Channel) => {
        PubSub.publish(Constants.PubSubArchiveResult, {
            channelId: channel.uuid,
            hasNext: false
        })
        const messageType = ChannelUtils.channelTypeToMessageType(channel.type)

        if (Config.shared.isStudent()) {
            PinHandler.shared.fetchPinnedMessages(channel.uuid, messageType, PinType.PRIVATE)
            PinHandler.shared.fetchPinnedMessages(channel.uuid, messageType, PinType.PUBLIC)
        } else if (Config.shared.isTeacher()) {
            PinHandler.shared.fetchPinnedMessages(channel.uuid, messageType, PinType.PUBLIC)
        }
    }

    private fetchArchiveMessages = (channelItem: Channel, latest: boolean = false) => {
        logger.leaveBreadcrumb("fetchArchiveMessages:", {
            channel: channelItem.uuid,
            latest: latest,
            name: channelItem.name,
            first: channelItem.firstMessageTimeHandle,
            previous: channelItem.hasPreviousMessages
        })

        if (!latest && !channelItem.hasPreviousMessages) {
            logger.leaveBreadcrumb("fetchArchiveMessages: done ")
            this.onArchivefetchCompleted(channelItem)
            return false;
        }

        const queryHandle = latest ? (Date.now() * 1000) : channelItem.firstMessageTimeHandle;

        const queryKey = latest ? channelItem.uuid : channelItem.uuid + "::" + queryHandle;

        if (this.latestArchive.has(queryKey)) {
            return false;
        }

        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.MESSAGE
        pkt[Packet.Keys.SUB_TYPE] = Packet.Message.Types.ARCHIVE;
        pkt[Packet.Common.CHANNEL] = channelItem.uuid;
        pkt[Packet.Query.HANDLE] = queryHandle;
        pkt[Packet.Message.Keys.MSG_TYPE] = ChannelUtils.channelTypeToMessageType(channelItem.type);
        pkt[Packet.Query.ORDER] = Packet.Query.ORDER_PREV
        pkt[Packet.Keys.ID] = uuidV4();
        pkt[Packet.Query.LIMIT] = Constants.ARCHIVE_MESSAGE_PAGINATION_LIMIT;

        if (ChatClient.shared.sendPacket(pkt, undefined, true)) {
            this.latestArchive.set(queryKey, true)
            pkt.latest = latest

            return true
        } else {
            return false
        }
    };

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.Message.Types.EVENT:
                {
                    logger.debug("MessageHandler: channel opened logged")
                }
                break
            case Packet.Message.Types.CHAT_STATE_TYPING:
                {
                    const channel = res[Packet.Common.CHANNEL] as string;
                    const from = this.parseTypingProfile(res)

                    CallbackHandler.shared.callOnTypingStateChanged(channel, from, true)
                }
                break;
            case Packet.Message.Types.CHAT_STATE_CLEAR:
                {
                    const channel = res[Packet.Common.CHANNEL] as string;
                    const from = this.parseTypingProfile(res)

                    CallbackHandler.shared.callOnTypingStateChanged(channel, from, false)
                }
                break;
            case Packet.Message.Types.CHAT:
                {
                    if (Packet.Keys.RESPONSE_CODE in res) {

                        const success = res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK;

                        if (success) {

                            const channelId = res[Packet.Common.CHANNEL] as string;
                            const msgId = res[Packet.Keys.ID] as string

                            const timestamp = res[Packet.Common.TIMESTAMP] as number;
                            const timeHandle = res[Packet.Message.Keys.TIME_HANDLE] as number;

                            await MessageDBOps.shared.updateMessageStateToSent(channelId, msgId, timestamp, timeHandle)
                        } else {

                            const channelId = queued!.packet[Packet.Common.CHANNEL];
                            const msgId = queued!.packet[Packet.Keys.ID]
                            const errorText = res[Packet.Error.Keys.ERROR];
                            const errorDesc = res[Packet.Error.Keys.DESCRIPTION];

                            await MessageDBOps.shared.updateMessageStateToRejected(channelId, msgId, errorText, errorDesc)
                        }
                    } else {
                        const decoded = this.convertWireToMessage(res);

                        if (decoded.user) {
                            await UserDBOps.shared.updateUser(decoded.user, decoded.isFriend)
                        }

                        await MessageDBOps.shared.addMessage(decoded.message);

                        Config.shared.updateLastMessageTimeHandle(decoded.message.timeHandle, decoded.message.type)

                        if (decoded.message.state === MessageState.RECEIVED) {
                            this.sendDeliveryReceipt(decoded.message)
                        }
                    }
                }
                break
            case Packet.Message.Types.SNAPSHOT:
                {
                    const d4 = moment()

                    const items: Array<RawPacket> = res[Packet.Message.Keys.MESSAGES];
                    const parsedItems = items.map(this.convertWireToMessage);

                    const processed = new Map<string, boolean>()
                    await asyncForEach(parsedItems, async (item) => {

                        if (item.user && !processed.has(item.user.uuid)) {
                            processed.set(item.user.uuid, true)
                            await UserDBOps.shared.updateUser(item.user, item.isFriend)
                            return
                        }

                        if (item.group && !processed.has(`${item.group.channel}:${item.group.member.uuid}`)) {
                            processed.set(`${item.group.channel}:${item.group.member.uuid}`, true)
                            await GroupDBOps.shared.updateMember(item.group.channel, item.group.member)
                        }
                    })

                    logger.leaveBreadcrumb("time: snapshot: user/group: ", {
                        delta: moment().diff(d4, 'ms')
                    }, 'log')

                    await MessageDBOps.shared.updateSnapshotMessages(parsedItems)

                    logger.leaveBreadcrumb("time: snapshot: db update", {
                        delta: moment().diff(d4, 'ms')
                    }, 'log')
                }
                break
            case Packet.Message.Types.GROUP_ACTION:
                {
                    await this.onGroupActionReceived(res)
                }
                break
            case Packet.Message.Types.RECEIPT_INFO:
                {
                    const channelId = queued!.packet[Packet.Common.CHANNEL]
                    const messageType = queued!.packet[Packet.Message.Keys.MSG_TYPE] as MessageType

                    const deliveryHandle = res[Packet.Message.Receipt.DELIVERY_HANDLE]
                    const readHandle = res[Packet.Message.Receipt.READ_HANDLE]

                    const channel = new Channel()
                    channel.uuid = channelId
                    channel.type = ChannelUtils.messageTypeToChannelType(messageType)

                    const info: RecieptInfo = { delivery: deliveryHandle, read: readHandle }

                    this.addReceiptInfo(channelId, info)

                    this.fetchArchiveMessages(channel, true)
                }
                break
            case Packet.Message.Types.RECEIPTS:
                {
                    const item: ReceiptItem = {
                        deliveredId: "",
                        readId: "",
                        deleteId: "",
                        channel: "",
                        user: "",
                        from: "",
                        timeHandle: 0,
                        messageType: MessageType.NONE,
                        isQueryResponse: false
                    }

                    if (!!queued) {
                        item.deliveredId = queued.packet[Packet.Message.Receipt.DELIVERY_ID]
                        item.readId = queued.packet[Packet.Message.Receipt.READ_ID]
                        item.deleteId = queued.packet[Packet.Message.Receipt.DELETE_ID]
                        item.channel = queued.packet[Packet.Common.CHANNEL];
                        item.user = queued.packet[Packet.Common.TO];
                        item.from = Config.shared.myUUID()
                        item.timeHandle = queued.packet[Packet.Message.Keys.TIME_HANDLE];
                        item.messageType = queued.packet[Packet.Message.Keys.MSG_TYPE];
                        item.isQueryResponse = true;
                    } else {
                        item.deliveredId = res[Packet.Message.Receipt.DELIVERY_ID]
                        item.readId = res[Packet.Message.Receipt.READ_ID]
                        item.deleteId = res[Packet.Message.Receipt.DELETE_ID]
                        item.channel = res[Packet.Common.CHANNEL];
                        item.from = res[Packet.Common.FROM];
                        item.user = res[Packet.Common.USER];
                        item.timeHandle = res[Packet.Message.Keys.TIME_HANDLE];
                        item.messageType = res[Packet.Message.Keys.MSG_TYPE];
                    }

                    await MessageDBOps.shared.saveReceipt(item)
                    queued?.resolve?.()
                }
                break
            case Packet.Message.Types.ARCHIVE:
                {
                    //0th is latest
                    //nth is oldest

                    const channelId = queued!.packet[Packet.Common.CHANNEL] as string;
                    const messageType = queued!.packet[Packet.Message.Keys.MSG_TYPE] as MessageType;
                    const latest = (queued!.packet["latest"] as boolean) || false;

                    const rawItems: Array<RawPacket> = res?.[Packet.Message.Keys.MESSAGES] || [];

                    if (latest) {
                        this.latestArchive.set(channelId, true);
                    }

                    const d1 = moment()
                    const parsedItems = rawItems.map(this.convertWireToMessage);
                    logger.leaveBreadcrumb("time:archive: parsing...", {
                        delta: moment().diff(d1, 'ms')
                    }, 'log')

                    const nextQueryHandle = res[Packet.Query.HANDLE] as number

                    const processed = new Map<string, boolean>()
                    await asyncForEach(parsedItems, async (item) => {

                        if (item.user && !processed.has(item.user.uuid)) {
                            processed.set(item.user.uuid, true)
                            await UserDBOps.shared.updateUser(item.user, item.isFriend)
                            return
                        }

                        if (item.group && !processed.has(`${item.group.channel}:${item.group.member.uuid}`)) {
                            processed.set(`${item.group.channel}:${item.group.member.uuid}`, true)
                            await GroupDBOps.shared.updateMember(channelId, item.group.member)
                        }
                    })

                    logger.leaveBreadcrumb("time:archive: user/group insertion...", {
                        delta: moment().diff(d1, 'ms')
                    }, 'log')

                    const messages = parsedItems.map(x => x.message)

                    const latestReceiptInfo: RecieptInfo =
                        this.latestReceiptInfo.get(channelId)
                        || { read: 0, delivery: 0 }

                    const d2 = moment()

                    await MessageDBOps.shared.addArchiveMessages(
                        channelId,
                        messageType,
                        messages,
                        nextQueryHandle,
                        latest,
                        latestReceiptInfo)

                    logger.leaveBreadcrumb("time:archive: user/group archive insertion...", {
                        delta: moment().diff(d2, 'ms')
                    }, 'log')


                    const chan = await ChannelDBOps.shared.getChannel(channelId)

                    if (chan !== undefined) {
                        this.fetchPreviousChannelMessages(chan)
                    }

                    queued?.resolve?.()
                }
                break
            default:
                {
                    logger.leaveBreadcrumb("MessageHandler: unrecognized", {
                        type: res
                    })
                    logger.error("MessageHandler: unrecognized");
                }
                break
        }
    }

    parseTypingProfile = (res: RawPacket): User | Member => {
        const role: UserRole = res[Packet.Account.Keys.ROLE]
        const grole: GroupRole | undefined = res[Packet.Group.Keys.ROLE]

        const from = {
            uuid: res[Packet.Common.FROM] as string,
            name: res[Packet.Account.Keys.NAME] as string,
            avatar: res[Packet.Account.Keys.AVATAR] as string,
            timestamp: Date.now()
        }

        if (grole !== undefined) {
            const member = from as Member
            member.grole = grole
            member.urole = role

            return member

        } else {
            const user = from as User
            user.role = role

            return user
        }
    }

    parseGroupActionPacket = (res: RawPacket): {
        message: Message,
        member?: Member,
        memberCount: number
    } => {
        const messageType = res[Packet.Message.Keys.MSG_TYPE] as MessageType;

        switch (messageType) {
            case MessageType.GROUP_CREATE:
                {
                    const channelId = res[Packet.Common.CHANNEL]
                    const creator = res[Packet.Common.USER]

                    const message = new Message()
                    message.id = res[Packet.Keys.ID]
                    message.from = creator
                    message.channel = channelId
                    message.to = channelId
                    message.type = MessageType.GROUP_CREATE
                    message.state = MessageState.READ_SENT

                    message.timestamp = res[Packet.Common.TIMESTAMP]
                    message.timeHandle = res[Packet.Message.Keys.TIME_HANDLE]

                    message.sender.uuid = creator
                    message.sender.name = res[Packet.Account.Keys.NAME]
                    message.sender.avatar = res[Packet.Account.Keys.AVATAR]
                    message.sender.role = res[Packet.Account.Keys.ROLE]

                    message.text = `${message.sender.name} created the group`

                    return {
                        message: message,
                        memberCount: res[Packet.Group.Keys.COUNT] || -1
                    }
                }
            case MessageType.JOIN_GROUP:
                {
                    const channelId = res[Packet.Common.CHANNEL]
                    const addUserRaw = res[Packet.Group.Keys.ADD_USER_PROF]
                    const callUserRaw = res[Packet.Group.Keys.PARENT_USER_PROF]

                    const addedUser: {
                        user: string,
                        name: string,
                        avatar: string,
                        role: UserRole
                    } = {
                        user: res[Packet.Common.USER],
                        name: addUserRaw[Packet.Account.Keys.NAME],
                        avatar: addUserRaw[Packet.Account.Keys.AVATAR],
                        role: addUserRaw[Packet.Account.Keys.ROLE]
                    }

                    const callerUser: {
                        user: string,
                        name: string,
                        avatar: string,
                        role: UserRole
                    } = {
                        user: res[Packet.Common.FROM],
                        name: callUserRaw[Packet.Account.Keys.NAME],
                        avatar: callUserRaw[Packet.Account.Keys.AVATAR],
                        role: callUserRaw[Packet.Account.Keys.ROLE]
                    }

                    const message = new Message()
                    message.id = res[Packet.Keys.ID]
                    message.from = callerUser.user
                    message.channel = channelId
                    message.to = addedUser.user
                    message.type = MessageType.JOIN_GROUP
                    message.state = MessageState.READ_SENT

                    if (addedUser.user === Config.shared.myUUID()) {
                        message.text = `You joined`
                    }
                    else if (addedUser.user === callerUser.user) {
                        message.text = `${addedUser.name} joined`
                    } else if (callerUser.user === Config.shared.myUUID()) {
                        message.text = `You added ${addedUser.name}`
                    } else {
                        message.text = `${callerUser.name} added ${addedUser.name}`
                    }

                    message.timestamp = res[Packet.Common.TIMESTAMP]
                    message.timeHandle = res[Packet.Message.Keys.TIME_HANDLE]

                    message.sender.uuid = callerUser.user
                    message.sender.name = callerUser.name
                    message.sender.avatar = callerUser.avatar
                    message.sender.role = callerUser.role

                    message.receiver.uuid = addedUser.user
                    message.receiver.name = addedUser.name
                    message.receiver.avatar = addedUser.avatar
                    message.receiver.role = addedUser.role

                    const member = new Member()
                    member.uuid = addedUser.user
                    member.name = addedUser.name
                    member.avatar = addedUser.avatar
                    member.urole = addedUser.role
                    member.grole = res[Packet.Group.Keys.ROLE]
                    member.timestamp = message.timestamp

                    return {
                        message: message,
                        member: member,
                        memberCount: res[Packet.Group.Keys.COUNT] || -1
                    }
                }
            case MessageType.LEAVE_GROUP:
                {
                    const channelId = res[Packet.Common.CHANNEL]
                    const remUserRaw = res[Packet.Group.Keys.REM_USER_PROF]
                    const callUserRaw = res[Packet.Group.Keys.PARENT_USER_PROF]

                    const removedUser: {
                        user: string,
                        name: string,
                        avatar: string,
                        role: UserRole
                    } = {
                        user: res[Packet.Common.USER],
                        name: remUserRaw[Packet.Account.Keys.NAME],
                        avatar: remUserRaw[Packet.Account.Keys.AVATAR],
                        role: remUserRaw[Packet.Account.Keys.ROLE]
                    }

                    const callerUser: {
                        user: string,
                        name: string,
                        avatar: string,
                        role: UserRole
                    } = {
                        user: res[Packet.Common.FROM],
                        name: callUserRaw[Packet.Account.Keys.NAME],
                        avatar: callUserRaw[Packet.Account.Keys.AVATAR],
                        role: callUserRaw[Packet.Account.Keys.ROLE]
                    }

                    const message = new Message()
                    message.id = res[Packet.Keys.ID]
                    message.from = callerUser.user
                    message.channel = channelId
                    message.to = removedUser.user
                    message.type = MessageType.LEAVE_GROUP
                    message.state = MessageState.READ_SENT

                    if (removedUser.user === Config.shared.myUUID()) {
                        message.text = `You left`
                    }
                    else if (removedUser.user === callerUser.user) {
                        message.text = `${removedUser.name} left`
                    } else if (removedUser.user === Config.shared.myUUID()) {
                        message.text = `You removed ${removedUser.name}`
                    } else {
                        message.text = `${callerUser.name} removed ${removedUser.name}`
                    }

                    message.timestamp = res[Packet.Common.TIMESTAMP]
                    message.timeHandle = res[Packet.Message.Keys.TIME_HANDLE]

                    message.sender.uuid = callerUser.user
                    message.sender.name = callerUser.name
                    message.sender.avatar = callerUser.avatar
                    message.sender.role = callerUser.role

                    message.receiver.uuid = removedUser.user
                    message.receiver.name = removedUser.name
                    message.receiver.avatar = removedUser.avatar
                    message.receiver.role = removedUser.role

                    return {
                        message: message,
                        memberCount: res[Packet.Group.Keys.COUNT] || -1
                    }
                }
            case MessageType.MUC_MEMBER_ROLE_CHANGED:
                {
                    const message = new Message()
                    message.id = res[Packet.Keys.ID]
                    message.channel = res[Packet.Common.CHANNEL]
                    message.from = res[Packet.Common.CHANNEL]

                    message.to = message.channel
                    message.type = MessageType.MUC_MEMBER_ROLE_CHANGED
                    message.state = MessageState.READ_SENT
                    message.timestamp = res[Packet.Common.TIMESTAMP]
                    message.timeHandle = res[Packet.Message.Keys.TIME_HANDLE]

                    const userRaw = res[Packet.Group.Keys.USER_PROF]

                    const changedUser: {
                        user: string,
                        name: string,
                        avatar: string,
                        role: UserRole
                    } = {
                        user: res[Packet.Common.USER],
                        name: userRaw[Packet.Account.Keys.NAME],
                        avatar: userRaw[Packet.Account.Keys.AVATAR],
                        role: userRaw[Packet.Account.Keys.ROLE]
                    }

                    const member = new Member()
                    member.uuid = changedUser.user
                    member.grole = res[Packet.Group.Keys.ROLE]
                    member.name = changedUser.name
                    member.avatar = changedUser.avatar
                    member.urole = changedUser.role
                    member.timestamp = message.timestamp

                    if (changedUser.user === Config.shared.myUUID() && member.grole === GroupRole.ADMIN) {
                        message.text = `You are now an admin`
                    } else if (changedUser.user === Config.shared.myUUID() && member.grole === GroupRole.ADMIN) {
                        message.text = `You are now a member`
                    } else if (member.grole === GroupRole.ADMIN) {
                        message.text = `${changedUser.name} is now an admin`
                    } else {
                        message.text = `${changedUser.name} is now a member`
                    }

                    return {
                        message: message,
                        member: member,
                        memberCount: res[Packet.Group.Keys.COUNT] || -1
                    }
                }
            case MessageType.MUC_MEMBER_INVITE:
                {
                    const message = new Message()

                    message.id = res[Packet.Keys.ID]
                    message.channel = res[Packet.Common.CHANNEL]
                    message.text = res[Packet.Message.Data.TEXT]
                    message.timestamp = res[Packet.Common.TIMESTAMP]
                    message.timeHandle = message.timestamp * 1000
                    message.type = MessageType.MUC_MEMBER_INVITE
                    message.state = MessageState.RECEIVED

                    return {
                        message: message,
                        memberCount: res[Packet.Group.Keys.COUNT] || -1
                    }
                }
            default:
                {
                    logger.leaveBreadcrumb("MessageHandler: parseGroupActionPacket", {
                        type: res
                    })
                    logger.error("MessageHandler: parseGroupActionPacket");

                    throw "unrecognized"
                }
        }
    }

    onGroupActionReceived = async (res: RawPacket) => {
        const parsed = this.parseGroupActionPacket(res)

        switch (parsed.message.type) {
            case MessageType.GROUP_CREATE:
                {
                    await MessageDBOps.shared.addMessage(parsed.message)
                }
                break
            case MessageType.JOIN_GROUP:
                {
                    await MessageDBOps.shared.addMessage(parsed.message)
                    await GroupDBOps.shared.updateChannelMemberCount(parsed.message.channel, parsed.memberCount)
                    await GroupDBOps.shared.updateMember(parsed.message.channel, parsed.member!, true)
                }
                break
            case MessageType.LEAVE_GROUP:
                {
                    await MessageDBOps.shared.addMessage(parsed.message)
                    await GroupDBOps.shared.updateChannelMemberCount(parsed.message.channel, parsed.memberCount)
                    await GroupDBOps.shared.removeGroupMember(parsed.message.channel, parsed.message.receiver.uuid, parsed.message.timestamp)
                }
                break
            case MessageType.MUC_MEMBER_ROLE_CHANGED:
                {
                    await MessageDBOps.shared.addMessage(parsed.message)
                    await GroupDBOps.shared.updateChannelMemberCount(parsed.message.channel, parsed.memberCount)
                    await GroupDBOps.shared.updateMember(parsed.message.channel, parsed.member!)
                }
                break
            case MessageType.MUC_MEMBER_INVITE:
                {

                    const invitedByRaw = res[Packet.Group.Keys.PARENT_USER_PROF];

                    const invitedBy = new Sender()
                    invitedBy.uuid = invitedByRaw[Packet.Common.USER]
                    invitedBy.name = invitedByRaw[Packet.Account.Keys.NAME]
                    invitedBy.avatar = invitedByRaw[Packet.Account.Keys.AVATAR]
                    invitedBy.role = invitedByRaw[Packet.Account.Keys.ROLE]

                    const item: InvitedGroupInfo = {
                        text: parsed.message.text,
                        channel: parsed.message.channel,
                        timestamp: parsed.message.timestamp,
                        name: res[Packet.Group.Keys.NAME],
                        avatar: res[Packet.Group.Keys.AVATAR],
                        grouptype: res[Packet.Group.Keys.SGROUP_TYPE],
                        role: res[Packet.Group.Keys.ROLE],
                        invitedBy: invitedBy
                    }

                    await GroupDBOps.shared.addInvitedGroup(item)
                }
                break
            case MessageType.NONE:
                {
                    logger.debug("onGroupActionReceived: none");
                }
                break;
            default:
                {
                    logger.debug("onGroupActionReceived: ", res);
                }
                break;
        }
    };

    cachedFile = (cacheKey: string): File | undefined => {
        return this.filesCache.get(cacheKey)
    }

    sendMessage = async (
        channel: Channel,
        text?: string,
        file?: File,
        replyOn?: Message,
        mentions?: Array<Mention>
    ) => {
        if ((text === undefined || text === "") && file === undefined) {
            return
        }

        const message = new Message()
        message.from = Config.shared.myUUID()
        message.id = uuidV4()
        message.channel = channel.uuid
        message.type = ChannelUtils.channelTypeToMessageType(channel.type)
        message.to = channel.otherUserId
        message.text = text || ''

        if (mentions) {
            message.mentions = mentions
        }

        if (replyOn) {
            message.reference.type = ReferenceType.REPLY

            message.reference.id = replyOn.id
            message.reference.text = replyOn.text
            message.reference.media = replyOn.media
            message.reference.timeHandle = replyOn.timeHandle
            message.reference.sender = replyOn.sender
        }

        message.timestamp = Date.now()
        message.timeHandle = message.timestamp * 1000

        await this.sendMessageInternal(message, file)
    }

    private sendMessageInternal = async (
        message: Message,
        file?: File
    ) => {
        const myUser = await db.user.get(Config.shared.myUUID())
        message.sender.uuid = myUser!.uuid
        message.sender.name = myUser!.name
        message.sender.avatar = myUser!.avatar
        message.sender.role = myUser!.role

        const thumbCacheKey = MessageUtils.thumbCacheKey(message)
        const fileCacheKey = MessageUtils.fileCacheKey(message)

        if (file) {
            const media = new Media()
            media.fileName = file.name
            media.mimeType = file.type
            media.size = file.size

            try {
                if (MessageUtils.isMediaVideo(media)) {
                    const cover = await FileUtils.getVideoCover(file)

                    if (cover.blob) {
                        const thumbFileName = `${FileUtils.removeExtension(media.fileName)}.jpg`

                        const thumbFile = new File([cover.blob], thumbFileName);
                        this.filesCache.set(thumbCacheKey, thumbFile!)

                        media.duration = cover.duration
                    }
                }
            } catch (e) {
                logger.leaveBreadcrumb("messagehandler.thumb.gen")
                logger.error(e)
            }

            message.media.push(media)
            this.filesCache.set(fileCacheKey, file)

            message.state = MessageState.UPLOADING
        } else {
            message.state = MessageState.TO_SEND
        }

        await MessageDBOps.shared.addMessage(message)

        const callbackSend = async (message: Message) => {
            let refMessage: Message | undefined

            if (message.reference.type === ReferenceType.REPLY) {
                refMessage = await db.message.get(message.reference.id)
            }

            const packet = this.convertMessageToWire(message, refMessage);
            ChatClient.shared.sendPacket(packet, undefined, true)
        }

        if (!file) {
            await callbackSend(message)
            return
        }

        try {
            const uploadResult = await S3Handler.shared.uploadFileToS3(file, S3ACL.Private, 0, message)

            message.media[0].thumbKey = uploadResult.fileKey
            message.media[0].dataKey = uploadResult.fileKey
            message.media[0].validUpto = Date.now() + Constants.OneDayMillis
            message.media[0].url = uploadResult.url

            if (MessageUtils.isMediaVideo(message.media[0]) && this.filesCache.has(thumbCacheKey)) {
                const thumbResult = await S3Handler.shared.uploadFileToS3(this.filesCache.get(thumbCacheKey)!, S3ACL.Private)

                message.media[0].thumbKey = thumbResult.fileKey
                message.media[0].url = thumbResult.url
            }

            const changes = {
                state: MessageState.TO_SEND,
                media: message.media
            };

            await db.message.update(message.id, changes);

            await callbackSend(message)
        } catch {
            await MessageDBOps.shared.updateMessageState(message.id, MessageState.UPLOAD_FAILED)
        } finally {
            this.filesCache.delete(MessageUtils.fileCacheKey(message))
            this.filesCache.delete(MessageUtils.thumbCacheKey(message))
        }
    }

    private convertMessageToWire = (message: Message, refMessage?: Message): RawPacket => {
        const packet: RawPacket = {};
        packet[Packet.Keys.ID] = message.id
        packet[Packet.Keys.TYPE] = Packet.Types.MESSAGE
        packet[Packet.Keys.SUB_TYPE] = Packet.Message.Types.CHAT
        packet[Packet.Message.Keys.MSG_TYPE] = message.type
        packet[Packet.Common.CHANNEL] = message.channel

        if (message.type === MessageType.P2P) {
            packet[Packet.Common.TO] = message.to
        }

        const payload: RawPacket = {}

        if (message.text) {
            payload[Packet.Message.Data.TEXT] = message.text
        }

        if (message.contact) {
            packet[Packet.Message.Data.CONTACT] = message.contact
        }

        if (message.link) {
            packet[Packet.Message.Data.LINK] = message.link
        }

        if (message.location.isValid) {
            const locInfo: RawPacket = {}
            locInfo[Packet.Message.Location.LATITUDE] = message.location.latitude
            locInfo[Packet.Message.Location.LONGITUDE] = message.location.longitude

            packet[Packet.Message.Data.LOCATION] = locInfo
        }

        if (message.media.length > 0) {
            payload[Packet.Message.Data.MEDIA] = message.media.map((media) => {
                const mediaItem: RawPacket = {};

                mediaItem[Packet.Message.Media.SIZE] = media.size;
                mediaItem[Packet.Message.Media.MIME] = media.mimeType;

                mediaItem[Packet.Message.Media.DATA_KEY] = media.dataKey;
                mediaItem[Packet.Message.Media.NAME] = media.fileName;

                if (media.thumbKey) {
                    mediaItem[Packet.Message.Media.THUMB_KEY] = media.thumbKey;
                } else if (MessageUtils.isMediaImage(media)) {
                    mediaItem[Packet.Message.Media.THUMB_KEY] = media.dataKey;
                }

                if (media.duration > 0) {
                    mediaItem[Packet.Message.Media.DURATION] = media.duration;
                }

                return mediaItem;
            });
        }

        packet[Packet.Message.Keys.DATA] = payload
        packet[Packet.Keys.ACK] = Packet.Values.TRUE

        if (message.reference.type !== ReferenceType.NONE && refMessage !== undefined) {
            const refItem: RawPacket = {};
            refItem[Packet.Message.Reference.TYPE] = message.reference.type
            refItem[Packet.Message.Reference.ID] = message.reference.id;
            refItem[Packet.Message.Keys.TIME_HANDLE] = message.reference.timeHandle

            if (refMessage && message.reference.type === ReferenceType.REPLY) {
                refItem[Packet.Message.Keys.MESSAGES] = [this.convertMessageToWire(refMessage)]
            }

            packet[Packet.Message.Keys.REFERENCE] = refItem;
        }

        packet[Packet.Message.Keys.MENTIONS] = message.mentions.map((item) => {
            const refItem: RawPacket = {};

            refItem[Packet.Common.USER] = item.user;
            refItem[Packet.Account.Keys.ROLE] = item.role;
            refItem[Packet.Message.Mention.START] = item.start;
            refItem[Packet.Message.Mention.END] = item.end;

            return refItem;
        });

        return packet;
    }

    convertWireToMessage = (pkt: RawPacket): WireMessage => {
        const messageType = pkt[Packet.Message.Keys.MSG_TYPE] as MessageType

        switch (messageType) {
            case MessageType.P2P:
            case MessageType.GROUP:
                {
                    return this.convertChatWirePacketToMessage(pkt)
                }
            default:
                {
                    const parsed = this.parseGroupActionPacket(pkt)
                    const result: WireMessage = {
                        message: parsed.message,
                        unread: 0,
                        isFriend: false,
                    }

                    if (parsed.member) {
                        result.group = {
                            channel: parsed.message.channel,
                            member: parsed.member,
                        }
                    }

                    return result
                }
        }
    }

    convertChatWirePacketToMessage = (pkt: RawPacket): WireMessage => {

        const result: WireMessage = {
            message: new Message(),
            unread: 0,
            isFriend: false,
            pinner: new Pinner()
        }

        result.unread = pkt[Packet.Recent.Keys.COUNT] || 0;
        result.message.id = pkt[Packet.Keys.ID];
        result.message.type = pkt[Packet.Message.Keys.MSG_TYPE];
        result.message.timestamp = pkt[Packet.Common.TIMESTAMP];

        result.message.from = pkt[Packet.Common.FROM];
        result.message.to = pkt[Packet.Common.TO];

        result.message.channel = pkt[Packet.Common.CHANNEL];
        result.message.timeHandle = pkt[Packet.Message.Keys.TIME_HANDLE] || 0

        const refMentions = pkt[Packet.Message.Keys.MENTIONS] || [];

        result.message.mentions = refMentions.map((refItem: RawPacket) => {
            const mention = new Mention()

            mention.user = refItem[Packet.Common.USER];
            mention.role = refItem[Packet.Account.Keys.ROLE];
            mention.start = refItem[Packet.Message.Mention.START];
            mention.end = refItem[Packet.Message.Mention.END];

            return mention;
        });

        if (result.message.type === MessageType.P2P && typeof pkt[Packet.Message.Keys.PROFILE] === "object") {
            const item: RawPacket = pkt[Packet.Message.Keys.PROFILE];

            result.message.sender.uuid = pkt[Packet.Common.FROM];
            result.message.sender.name = item[Packet.Account.Keys.NAME];
            result.message.sender.avatar = item[Packet.Account.Keys.AVATAR];
            result.message.sender.role = item[Packet.Account.Keys.ROLE];

            result.user = new User()
            result.user.uuid = result.message.sender.uuid
            result.user.name = result.message.sender.name
            result.user.avatar = result.message.sender.avatar
            result.user.role = result.message.sender.role
            result.user.timestamp = item[Packet.Common.TIMESTAMP];
            result.isFriend = item[Packet.Account.Keys.STATUS] === Packet.Values.TRUE;
        }

        if (typeof pkt[Packet.Pin.Keys.PROFILE] === "object") {
            result.pinner = new Pinner()

            result.pinner.uuid = pkt[Packet.Pin.Keys.USER];
            result.pinner.timestamp = pkt[Packet.Pin.Keys.TIMESTAMP];

            const item: RawPacket = pkt[Packet.Pin.Keys.PROFILE];

            result.pinner.name = item[Packet.Account.Keys.NAME];
            result.pinner.avatar = item[Packet.Account.Keys.AVATAR];
            result.pinner.role = item[Packet.Account.Keys.ROLE];
        }

        if (result.message.type === MessageType.GROUP && typeof pkt[Packet.Message.Keys.PROFILE] === "object") {
            const item: RawPacket = pkt[Packet.Message.Keys.PROFILE];

            result.message.sender.uuid = pkt[Packet.Common.FROM];
            result.message.sender.name = item[Packet.Account.Keys.NAME];
            result.message.sender.avatar = item[Packet.Account.Keys.AVATAR];
            result.message.sender.role = item[Packet.Account.Keys.ROLE];

            result.group = {
                member: new Member(),
                channel: result.message.channel
            }

            result.group.member.uuid = result.message.sender.uuid
            result.group.member.name = result.message.sender.name
            result.group.member.avatar = result.message.sender.avatar
            result.group.member.urole = result.message.sender.role
            result.group.member.grole = item[Packet.Group.Keys.ROLE];
            result.group.member.timestamp = item[Packet.Common.TIMESTAMP];
        }

        if (Config.shared.isCare()) {
            if (result.message.from === Packet.Common.CARE) {
                result.message.state = MessageState.SENT;
            } else {
                result.message.state = MessageState.RECEIVED;
            }
        }
        else if (result.message.from === Config.shared.cached().authInfo.uuid) {
            result.message.state = MessageState.SENT;
        } else {
            result.message.state = MessageState.RECEIVED;
        }

        if (pkt[Packet.Message.Keys.MSG_STATE] == ServerMessageState.DELETED) {
            result.message.state = MessageState.SENDER_DELETED
            result.message.text = Constants.MessageRemoveText
            result.message.isDeleted = true
        }

        if (result.message.state === MessageState.RECEIVED) {
            result.message.state = MessageState.DELIVERY_SENT
        }

        const packetData = pkt[Packet.Message.Keys.DATA];

        if (!result.message.isDeleted && typeof packetData === "object") {
            const data: RawPacket = pkt[Packet.Message.Keys.DATA];

            const text = data[Packet.Message.Data.TEXT];
            if (typeof text === "string") {
                result.message.text = text;
            }

            const medias = data[Packet.Message.Data.MEDIA];

            if (Array.isArray(medias)) {
                const kv = {
                    size: Packet.Message.Media.SIZE,
                    mimeType: Packet.Message.Media.MIME,
                    fileName: Packet.Message.Media.NAME,
                    thumbKey: Packet.Message.Media.THUMB_KEY,
                    duration: Packet.Message.Media.DURATION,
                    dataKey: Packet.Message.Media.DATA_KEY
                }

                result.message.media = medias.map((item: RawPacket) => {
                    const media = new Media()

                    Object.entries(kv).forEach(([k, v]) => {
                        if (v in item) {
                            media[k] = item[v]
                        }
                    })

                    const dataUrl = item[Packet.Message.Media.DATA_URL]
                    const thumbUrl = item[Packet.Message.Media.THUMB_URL]
                    const isVideo = MessageUtils.isMediaVideo(media)

                    if (isVideo && thumbUrl) {
                        media.url = thumbUrl
                        media.validUpto = MessageUtils.mediaLinkValidity()
                    }

                    if (!isVideo && dataUrl) {
                        media.url = thumbUrl
                        media.validUpto = MessageUtils.mediaLinkValidity()
                    }

                    return media;
                });
            }

            result.message.link = data[Packet.Message.Data.LINK] || ''
            result.message.contact = data[Packet.Message.Data.CONTACT] || ''

            const location = data[Packet.Message.Data.LOCATION]
            if (location) {
                result.message.location.latitude = location[Packet.Message.Location.LATITUDE]
                result.message.location.longitude = location[Packet.Message.Location.LONGITUDE]

                if (result.message.location.latitude !== -1 && result.message.location.longitude !== -1) {
                    result.message.location.isValid = true
                }
            }
        }

        if (!result.message.isDeleted && typeof pkt[Packet.Message.Keys.REFERENCE] === "object") {
            const refData: RawPacket = pkt[Packet.Message.Keys.REFERENCE];

            const refType = refData[Packet.Message.Reference.TYPE] as ReferenceType
            const refMessageItems = refData[Packet.Message.Keys.MESSAGES] || [];

            if (
                refType === ReferenceType.REPLY &&
                Array.isArray(refMessageItems) &&
                refMessageItems.length === 1
            ) {
                const decoded = this.convertWireToMessage(refMessageItems[0]);

                result.message.reference.type = refType

                result.message.reference.id = refData[Packet.Message.Reference.ID];
                result.message.reference.timeHandle = refData[Packet.Message.Keys.TIME_HANDLE];

                result.message.reference.text = decoded.message.text;
                result.message.reference.media = decoded.message.media;
                result.message.reference.sender = decoded.message.sender;
                result.message.reference.mentions = decoded.message.mentions;
            }
        }

        //parse flags
        if (!result.message.isDeleted && typeof pkt[Packet.Message.Keys.FLAGS] === 'object') {
            const flags: RawPacket = pkt[Packet.Message.Keys.FLAGS];
            result.message.flags.isCare = flags[Packet.Message.Flags.FLAG_TYPE_CARE] === Packet.Values.TRUE
            result.message.flags.lastMessageTimeHandle = flags[Packet.Message.Flags.FLAG_TYPE_LAST_MESSAGE_TH]
            result.message.flags.isAutomated = flags[Packet.Message.Flags.FLAG_TYPE_AUTOMATED] === Packet.Values.TRUE

            if (result.message.flags.isAutomated) {
                result.message.state = MessageState.DELIVERY_SENT
            }
        }

        if (!result.message.flags.isCare && result.message.from === Constants.CareUser) {
            result.message.flags.isCare = true
        }

        if (Config.shared.isCare() && typeof pkt[Packet.Message.Keys.PROFILE_CARE] === "object") {
            const item: RawPacket = pkt[Packet.Message.Keys.PROFILE_CARE];

            result.message.sender.uuid = item[Packet.Common.USER];
            result.message.sender.name = item[Packet.Account.Keys.NAME];
            result.message.sender.avatar = item[Packet.Account.Keys.AVATAR];
            result.message.sender.role = item[Packet.Account.Keys.ROLE];
        }

        return result;
    }
}