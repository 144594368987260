import ChannelType from "../enums/ChannelType";
import GroupCreateMode from "../enums/GroupCreateMode";
import GroupType from "../enums/GroupType";
import MessageState from "../enums/MessageState";
import Packet from "../enums/Packet";
import Member from "./Member";
import { Sender } from "./Message";
import User from "./User";

export class LastMessage {
    id: string = ""
    timeHandle = 0
    state = MessageState.NONE
    text: string = ""
}

export default class Channel {

    uuid: string = "";

    type: ChannelType = ChannelType.NONE;

    name: string = "";
    avatar: string = "";
    desc: string = "";

    lastMessage = new LastMessage()

    otherUserId: string = "";

    unread: number = 0;

    isFriend: boolean = false
    isMuted: boolean = false

    isGroupAdmin: boolean = false
    isGroupMember: boolean = false

    hasPreviousMessages: boolean = true
    firstMessageTimeHandle: number = Packet.Values.UNKNOWN

    groupType: GroupType = GroupType.UNKNOWN;

    members: Map<string, Member> = new Map();

    // isPendingJoin: boolean = false
    isInviteActionPending: number = Packet.Values.FALSE

    groupCreateMode: GroupCreateMode = GroupCreateMode.API
    groupJoinToken: string = ""

    isDeleted = false

    memberCount: number = 0

    isCommunityGroup: number = Packet.Values.FALSE

    invitedBy?: Sender = undefined
}
