import { getAuthUserInfo } from "../../network/cookie"
import Constants from "../config/Constants";
import ChannelType from "../enums/ChannelType";
import GroupRole from "../enums/GroupRole";
import GroupType from "../enums/GroupType";
import MessageType from "../enums/MessageType";
import Packet from "../enums/Packet";
import UserRole from "../enums/UserRole";
import Channel from "../models/Channel";
import Member from "../models/Member";
import User from "../models/User";
import UserUtils from "./UserUtils";

class ChannelUtils {
    static groupRoleToHuman = (role: GroupRole) => {
        switch (role) {
            case GroupRole.ADMIN:
                return "Admin"
            case GroupRole.MEMBER:
                return "Member"
            default:
                return ""
        }
    }

    static otherChannelUser = (channel: string, firstUser: string): string => {
        let parts = channel.split(":");
        if (parts.length !== 2) {
            return ""
        }

        if (parts[0] === firstUser) {
            return parts[1]
        } else {
            return parts[0]
        }
    }

    static messageTypeToChannelType = (type: MessageType): ChannelType => {
        switch (type) {
            case MessageType.P2P:
                return ChannelType.P2P
            case MessageType.GROUP:
            case MessageType.JOIN_GROUP:
            case MessageType.LEAVE_GROUP:
            case MessageType.MUC_MEMBER_ROLE_CHANGED:
                return ChannelType.GROUP
            default:
                return ChannelType.NONE
        }
    }

    static channelTypeToMessageType = (type: ChannelType): MessageType => {
        switch (type) {
            case ChannelType.P2P:
                return MessageType.P2P
            case ChannelType.GROUP:
                return MessageType.GROUP
            default:
                return MessageType.NONE
        }
    }

    static makeChannel = (
        otherUser: string,
        otherRole: UserRole,
    ) => {
        const authInfo = getAuthUserInfo()

        const parts = [
            UserUtils.IsRoleCare(authInfo.role) ? Constants.CareUser : authInfo.uuid!,
            UserUtils.IsRoleCare(otherRole) ? Constants.CareUser : otherUser,
        ];

        return parts.sort().join(":");
    }

    static queryToType = (type: string) => {
        switch (type) {
            case "p2p":
            case "care":
                return ChannelType.P2P
            case "grp":
            case "muc":
                return ChannelType.GROUP
            default:
                return ChannelType.NONE
        }
    }

    static memberToUser = (member: Member): User => {
        const user = new User()
        user.uuid = member.uuid
        user.avatar = member.avatar
        user.name = member.name
        user.role = member.urole
        user.timestamp = member.timestamp

        return user
    }

    static isInvitePending = (channel: Channel): boolean => {
        return channel.isInviteActionPending === Packet.Values.TRUE
    }

    static unreadVal = (channel: Channel): string | undefined => {
        if (ChannelUtils.isInvitePending(channel)) {
            return "1"
        }

        if (channel.unread === 0) {
            return undefined
        }

        if (channel.unread > 99) {
            return "99+"
        }

        return `${channel.unread}`
    }

    static memberCount = (channel: Channel): string => {
        const count = this.memberCountInt(channel)

        const append = count > 1 ? "members" : "member"

        return `${count} ${append}`
    }

    static memberCountInt = (channel: Channel): number => {

        if (channel.groupType !== GroupType.PRIVATE) {
            return channel.memberCount
        } else {
            return Array.from(channel.members.values()).reduce((accumulator, currentValue) => {
                if (currentValue.grole !== GroupRole.NONE) {
                    return accumulator + 1
                }

                return accumulator
            }, 0)
        }
    }
}

export default ChannelUtils