import Packet from "../enums/Packet"
import Config from "../models/Config";
import QueuedPacket from "../packets/QueuedPacket"
import RawPacket from "../packets/RawPacket"
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient";
import GroupRole from "../enums/GroupRole";
import GroupType from "../enums/GroupType";
import Constants from "../config/Constants";
import GroupDBOps, { InvitedGroupInfo } from "../database/GroupDBOps";
import ResponseCode from "../enums/ResponseCode";
import UserRole from "../enums/UserRole";
import { logger } from "../../utils/Logger";
import Member from "../models/Member";
import Channel from "../models/Channel";
import ChannelType from "../enums/ChannelType";
import MessageHandler from "./MessageHandler";
import ChannelDBOps from "../database/ChannelDBOps";
import { Sender } from "../models/Message";
import MessageState from "../enums/MessageState";
import { asyncForEach } from "../../utils/general";

export default class GroupHandler {

    static shared = new GroupHandler()

    fetchGroupByIds = (
        channels: Array<string>,
        queryHandle: number = 0,
        includeRecent: boolean = false
    ) => {

        if (channels.length == 0) {
            return
        }

        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.LIST_GROUPS
        pkt[Packet.Group.Keys.GROUPS] = channels;

        if (queryHandle > 0) {
            pkt[Packet.Query.HANDLE] = queryHandle;
        }

        pkt[Packet.Query.PAGE] = 0
        pkt[Packet.Query.LIMIT] = 1000;
        pkt[Packet.Keys.ID] = uuidV4();

        if (includeRecent) {
            pkt[Packet.Group.Keys.INCLUDE_RECENT] = Packet.Values.TRUE
        }

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    fetchAllJoinedGroups = () => {
        const queryHandle = Config.shared.cached().groupSyncTimeHandle
        return this.fetchJoinedGroups(GroupType.UNKNOWN, queryHandle)
    }

    private fetchJoinedGroups = (
        groupType: GroupType,
        queryHandle: number,
    ) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.LIST_GROUPS
        pkt[Packet.Group.Keys.GROUP_TYPE] = groupType;
        pkt[Packet.Query.HANDLE] = queryHandle;
        pkt[Packet.Query.PAGE] = 0
        pkt[Packet.Query.LIMIT] = 1000;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    createPrivateGroup = (
        name: string,
        description: string = '',
        avatar: string = Constants.DEF_GROUP_AVATAR,
    ): Promise<{
        success: true,
        channel: string
    } | {
        success: false,
        code: ResponseCode,
        error: string,
        desc: string
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.CREATE_GROUP;
        pkt[Packet.Keys.ID] = uuidV4();

        pkt[Packet.Group.Keys.NAME] = name;
        pkt[Packet.Group.Keys.DESCRIPTION] = description;
        pkt[Packet.Group.Keys.AVATAR] = avatar;
        pkt[Packet.Group.Keys.SGROUP_TYPE] = GroupType.PRIVATE;

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    updateGroupName = (name: string) => {
        this.updateGroupProfile(name, undefined, undefined)
    }

    updateGroupAvatar = (avatar: string) => {
        this.updateGroupProfile(undefined, avatar, undefined)
    }

    updateGroupDesc = (desc: string) => {
        this.updateGroupProfile(undefined, undefined, desc)
    }

    private updateGroupProfile = (
        name?: string,
        avatar?: string,
        description?: string
    ): Promise<void> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.PROFILE;
        pkt[Packet.Keys.ID] = uuidV4();

        if (name) {
            pkt[Packet.Group.Keys.NAME] = name;
        }

        if (avatar) {
            pkt[Packet.Group.Keys.AVATAR] = avatar;
        }

        if (description) {
            pkt[Packet.Group.Keys.DESCRIPTION] = description;
        }

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    deleteGroup = (channel: string) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.DELETE
        pkt[Packet.Common.CHANNEL] = channel;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    /** for role_teacher, role_student only current users profile is returned if they are member
     * for other groups queryHandle -> 0 then all current members profile is returned
     * if queryHandle > 0 then a log of members leaving/joining is returned 
    */
    fetchAllMembers = async (
        channel: string
    ) => {
        return this.fetchMembers(channel, 0)
    };

    private fetchMembers = (
        channel: string,
        queryHandle: number,
    ): Promise<{ members: Map<string, Member> }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.LIST_MEMBERS
        pkt[Packet.Common.CHANNEL] = channel
        pkt[Packet.Query.HANDLE] = queryHandle
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    fetchInvitedMembers = (channel: string): Promise<{
        members: Member[]
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.MEMBERS_INVITED
        pkt[Packet.Common.CHANNEL] = channel;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                resolve({ members: [] })
            }
        });
    }

    // invite to private group
    // receiver should join by calling joinInvitedGroup
    inviteMember = (channel: string, user: string): Promise<{
        success: true
    } | {
        success: false,
        error: string
    }> => {
        return this.addMember(channel, user, GroupRole.MEMBER)
    }

    // receiver can cancel inivite
    cancelInvite = (channel: string): Promise<void> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.INVITE_REM;
        pkt[Packet.Common.CHANNEL] = channel;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    //receiver joins group
    joinInvitedGroup = (channel: string): Promise<{
        success: true
    } | {
        success: false,
        error: string
    }> => {
        return this.addMember(channel, Config.shared.myUUID(), GroupRole.MEMBER);
    };

    // make a group join link
    fetchJoinLink = (channel: Channel): Promise<{
        success: true,
        code: number,
        link: string
    } | {
        success: false,
        error: string
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.JOIN_LINK
        pkt[Packet.Common.CHANNEL] = channel.uuid;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    fetchGroupDetailsForLink = (uuid: string, token: string): Promise<{
        isBlocked: boolean
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.JOIN_LINK_INFO
        pkt[Packet.Common.CHANNEL] = uuid
        pkt[Packet.Group.Keys.TOKEN] = token
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    fetchInvitedGroups = () => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.INVITED_GROUPS
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    joinGroup = (channel: Channel): Promise<{
        success: true
    } | {
        success: false,
        error: string
    }> => {
        // if (channel.groupType === GroupType.PUBLIC) {
        return this.addMember(channel.uuid, Config.shared.myUUID(), GroupRole.MEMBER, channel.groupJoinToken);
        // } else {
        //     return null
        // }
    }

    // // join group by link above
    // // above link contains ?token= param which needs to be sent here
    // joinGroupByLink = (channel: Channel): Promise<{
    //     success: true
    // } | {
    //     success: false,
    //     error: string
    // }> => {
    //     return this.addMember(channel, Config.shared.myUUID(), GroupRole.MEMBER, token);
    // }

    // change group member role to admin
    makeMemberAdmin = (channel: string, user: string): Promise<{
        success: true
    } | {
        success: false,
        error: string
    }> => {
        return this.addMember(channel, user, GroupRole.ADMIN);
    }

    // change group member role to member
    makeAdminMember = (channel: string, user: string): Promise<{
        success: true
    } | {
        success: false,
        error: string
    }> => {
        return this.addMember(channel, user, GroupRole.MEMBER);
    }

    /**
     * error: 
     * link -> share link not valid
     * invite -> user invite not available
     * blocked -> user is not allowed to join this group
     * validation -> 
     *  group not found, group type not supported
     *  max groups already joined
    */
    private addMember = (
        channel: string,
        user: string,
        groupRole: GroupRole = GroupRole.MEMBER,
        token?: string
    ): Promise<{
        success: true
    } | {
        success: false,
        error: string
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.MEMBER_ADD;
        pkt[Packet.Common.USER] = user;
        pkt[Packet.Common.CHANNEL] = channel;
        pkt[Packet.Group.Keys.ROLE] = groupRole;
        pkt[Packet.Keys.ID] = uuidV4();

        if (token) {
            pkt[Packet.Group.Keys.TOKEN] = token
        }

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    leaveGroup = (channel: Channel) => {
        return this.removeUserFromGroup(channel, Config.shared.myUUID())
    };

    removeUserFromGroup = (
        channel: Channel,
        memberId: string,
    ): Promise<{
        success: true
    } | {
        success: false,
        error: string
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.MEMBER_REM
        pkt[Packet.Common.CHANNEL] = channel.uuid;
        pkt[Packet.Common.USER] = memberId;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            ChatClient.shared.sendPacket(pkt, resolve, true);
        })
    };

    // member is removed and prevented from rejoining
    blockUser = (
        channel: string,
        user: string,
        reason: string,
    ): Promise<{
        success: true
    } | {
        success: false,
        error: string
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.BLOCK
        pkt[Packet.Common.USER] = user;
        pkt[Packet.Common.CHANNEL] = channel;
        pkt[Packet.Group.Keys.BLOCK_REASON] = reason;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            ChatClient.shared.sendPacket(pkt, resolve, true);
        })
    }

    // member is removed from group block list. 
    // member needs to be added seperately post this
    unblockUser = (
        channel: string,
        user: string,
    ): Promise<{
        success: true
    } | {
        success: false,
        error: string
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.UNBLOCK
        pkt[Packet.Common.USER] = user;
        pkt[Packet.Common.CHANNEL] = channel;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            ChatClient.shared.sendPacket(pkt, resolve, true);
        })
    }

    /**
     * users[0].timestamp -> this is the time user was blocked at
    */
    fetchBlockedMembers = (
        channel: string,
        page: number = 0,
    ): Promise<{
        success: true,
        more: boolean,
        users: Array<Member>
    } | {
        success: false,
        error: string
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.GROUP
        pkt[Packet.Keys.SUB_TYPE] = Packet.Group.Types.BLOCK_LIST
        pkt[Packet.Common.CHANNEL] = channel;
        pkt[Packet.Query.PAGE] = page
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            ChatClient.shared.sendPacket(pkt, resolve, true);
        })
    }

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.Group.Types.CREATE_GROUP:
                {
                    if (res[Packet.Keys.RESPONSE_CODE] == ResponseCode.FORBIDDEN) {

                        queued?.resolve?.({
                            success: false,
                            code: res[Packet.Keys.RESPONSE_CODE],
                            error: res[Packet.Error.Keys.ERROR],
                            desc: res[Packet.Error.Keys.DESCRIPTION],
                        })

                        return
                    }

                    await GroupDBOps.shared.addCreatedGroup(res)

                    queued?.resolve?.({
                        success: true,
                        channel: res[Packet.Common.CHANNEL]
                    })
                }
                break
            case Packet.Group.Types.PROFILE:
                {
                    if (queued) {
                        if (res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK) {
                            await GroupDBOps.shared.updateGroupProfileByMe(queued.packet)
                            queued?.resolve?.({
                                success: true,
                            })
                        } else {
                            queued?.resolve?.({
                                success: false,
                                error: res[Packet.Error.Keys.ERROR]
                            })
                        }
                    } else {
                        await GroupDBOps.shared.updateGroupProfilePush(res)
                    }
                }
                break
            case Packet.Group.Types.DELETE:
                {
                    const channelId = res[Packet.Common.CHANNEL]

                    if (queued) {
                        if (res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK) {
                            await GroupDBOps.shared.markGroupDeleted(channelId)
                            queued?.resolve?.({
                                success: true,
                            })
                        } else {
                            queued?.resolve?.({
                                success: false,
                                error: res[Packet.Error.Keys.ERROR]
                            })
                        }
                    } else {
                        await GroupDBOps.shared.markGroupDeleted(channelId)
                    }
                }
                break
            case Packet.Group.Types.JOIN_LINK:
                {
                    if (res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK) {

                        const link = res[Packet.Group.Keys.LINK]
                        const token = res[Packet.Group.Keys.TOKEN];

                        queued?.resolve?.({
                            success: true,
                            link: link,
                            token: token
                        })
                    } else {
                        queued?.resolve?.({
                            success: false,
                            error: res[Packet.Error.Keys.ERROR]
                        })
                    }
                }
                break
            case Packet.Group.Types.JOIN_LINK_INFO:
                {
                    const isBlocked = res[Packet.Group.Keys.IS_BLOCKED] === Packet.Values.TRUE

                    if (isBlocked) {
                        queued?.resolve?.({
                            isBlocked: isBlocked
                        })
                        return
                    }

                    const channel = new Channel()

                    channel.uuid = res[Packet.Common.CHANNEL]
                    channel.name = res[Packet.Group.Keys.NAME]
                    channel.avatar = res[Packet.Group.Keys.AVATAR]
                    channel.desc = res[Packet.Group.Keys.DESCRIPTION]
                    channel.type = ChannelType.GROUP
                    channel.groupType = res[Packet.Group.Keys.SGROUP_TYPE]
                    channel.memberCount = res[Packet.Group.Keys.COUNT]
                    channel.groupCreateMode = res[Packet.Group.Keys.CREATE_MODE]
                    channel.isGroupMember = res[Packet.Group.Keys.IS_MEMBER] === Packet.Values.TRUE

                    if (!channel.isGroupMember) {
                        const invitedByRaw = res[Packet.Group.Keys.PARENT_USER_PROF];

                        const invitedBy = new Sender()
                        invitedBy.uuid = invitedByRaw[Packet.Common.USER]
                        invitedBy.name = invitedByRaw[Packet.Account.Keys.NAME]
                        invitedBy.avatar = invitedByRaw[Packet.Account.Keys.AVATAR]
                        invitedBy.role = invitedByRaw[Packet.Account.Keys.ROLE]

                        channel.invitedBy = invitedBy

                        channel.isInviteActionPending = Packet.Values.TRUE
                        channel.groupJoinToken = queued!.packet[Packet.Group.Keys.TOKEN]

                        channel.lastMessage.state = MessageState.RECEIVED
                        channel.lastMessage.text = res[Packet.Message.Data.TEXT];
                        channel.lastMessage.timeHandle = res[Packet.Group.Keys.INVITE_TIMESTAMP] * 1000
                    }

                    const info = {
                        delivery: Date.now() * 1000,
                        read: Date.now() * 1000,
                    }

                    MessageHandler.shared.addReceiptInfo(channel.uuid, info)

                    await GroupDBOps.shared.addInviteByLinkGroup(channel)

                    queued?.resolve?.({
                        isBlocked: isBlocked
                    })
                }
                break
            case Packet.Group.Types.INVITED_GROUPS:
                {
                    const rawGroups: Array<RawPacket> = res[Packet.Group.Keys.GROUPS]

                    await asyncForEach(rawGroups, async (rawItem) => {

                        const invitedByRaw = rawItem[Packet.Group.Keys.PARENT_USER_PROF];

                        const invitedBy = new Sender()
                        invitedBy.uuid = invitedByRaw[Packet.Common.USER]
                        invitedBy.name = invitedByRaw[Packet.Account.Keys.NAME]
                        invitedBy.avatar = invitedByRaw[Packet.Account.Keys.AVATAR]
                        invitedBy.role = invitedByRaw[Packet.Account.Keys.ROLE]

                        const parsed: InvitedGroupInfo = {
                            text: `${invitedBy.name} has invited you to join ${rawItem[Packet.Group.Keys.NAME]}`,
                            channel: rawItem[Packet.Common.CHANNEL],
                            timestamp: rawItem[Packet.Common.TIMESTAMP],
                            name: rawItem[Packet.Group.Keys.NAME],
                            avatar: rawItem[Packet.Group.Keys.AVATAR],
                            grouptype: rawItem[Packet.Group.Keys.SGROUP_TYPE],
                            role: rawItem[Packet.Group.Keys.ROLE],
                            invitedBy: invitedBy
                        }

                        await GroupDBOps.shared.addInvitedGroup(parsed)
                    })
                }
                break
            case Packet.Group.Types.INVITE_REM:
                {
                    const channelId = queued!.packet![Packet.Common.CHANNEL]
                    await GroupDBOps.shared.cancelInvite(channelId)
                    ChatClient.shared.removeChannelLoadedCache(channelId)
                    queued?.resolve?.()
                }
                break
            case Packet.Group.Types.INVITE_IGNORE:
                {
                    const channelId = res[Packet.Common.CHANNEL]
                    const userId = res[Packet.Common.USER]

                    PubSub.publish(Constants.PubSubInviteIgnored, {
                        channelId: channelId,
                        userId: userId
                    })
                }
                break
            case Packet.Group.Types.MEMBER_ADD:
                {
                    if (queued) {
                        if (res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK) {
                            queued?.resolve?.({
                                success: true,
                            })
                        } else {
                            queued?.resolve?.({
                                success: false,
                                error: res[Packet.Error.Keys.ERROR]
                            })
                        }
                    }
                }
                break
            case Packet.Group.Types.MEMBER_REM:
                {
                    if (queued) {
                        if (res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK) {

                            const channelId = queued.packet[Packet.Common.CHANNEL]
                            const remUserId = queued.packet[Packet.Common.USER]
                            const timestamp = res[Packet.Common.TIMESTAMP]

                            await GroupDBOps.shared.removeGroupMember(channelId, remUserId, timestamp)
                            queued?.resolve?.({
                                success: true,
                            })
                        } else {
                            queued?.resolve?.({
                                success: false,
                                error: res[Packet.Error.Keys.ERROR]
                            })
                        }
                    }
                }
                break
            case Packet.Group.Types.BLOCK:
            case Packet.Group.Types.UNBLOCK:
                {
                    if (res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK) {
                        queued?.resolve?.({
                            success: true,
                        })
                    } else {
                        queued?.resolve?.({
                            success: false,
                            error: res[Packet.Error.Keys.ERROR]
                        })
                    }
                }
                break
            case Packet.Group.Types.BLOCK_LIST:
                {
                    if (res[Packet.Keys.RESPONSE_CODE] === ResponseCode.OK) {

                        const users: RawPacket[] = res[Packet.Group.Keys.MEMBERS] || []
                        const decoded: Array<Member> = users.map((item) => {
                            const member = new Member()

                            member.uuid = item[Packet.Common.USER] as string
                            member.name = item[Packet.Account.Keys.NAME] || ''
                            member.urole = item[Packet.Account.Keys.ROLE] || UserRole.STUDENT
                            member.avatar = item[Packet.Account.Keys.AVATAR] || Constants.DEF_PROFILE_PIC
                            member.timestamp = item[Packet.Common.TIMESTAMP] * 1000

                            return member
                        })

                        queued?.resolve?.({
                            success: true,
                            users: decoded,
                            more: res[Packet.Query.MORE] === Packet.Values.TRUE
                        })
                    } else {
                        queued?.resolve?.({
                            success: false,
                            error: res[Packet.Error.Keys.ERROR]
                        })
                    }
                }
                break
            case Packet.Group.Types.MEMBERS_INVITED:
                {
                    const rawMembers: Array<RawPacket> = res[Packet.Group.Keys.MEMBERS] || []

                    const members: Array<Member> = rawMembers.map((item) => {
                        const member = new Member()

                        member.uuid = item[Packet.Common.USER] as string
                        member.name = item[Packet.Account.Keys.NAME] || ''
                        member.urole = item[Packet.Account.Keys.ROLE] || UserRole.STUDENT
                        member.avatar = item[Packet.Account.Keys.AVATAR] || Constants.DEF_PROFILE_PIC
                        member.grole = item[Packet.Group.Keys.ROLE]
                        member.timestamp = item[Packet.Common.TIMESTAMP]
                        member.isInvited = Packet.Values.TRUE

                        return member
                    })

                    queued?.resolve?.({ members: members })
                }
                break
            case Packet.Group.Types.LIST_MEMBERS:
                {
                    if (res[Packet.Keys.RESPONSE_CODE] !== ResponseCode.OK) {
                        logger.debug(`GroupHandler: LIST_MEMBERS: ${res[Packet.Error.Keys.ERROR]}`)
                    }

                    const channelId = queued!.packet![Packet.Common.CHANNEL]
                    const hasMore = res[Packet.Query.MORE] === Packet.Values.TRUE
                    const queryHandle = res[Packet.Query.HANDLE]

                    const rawMembers: Array<RawPacket> = res[Packet.Group.Keys.MEMBERS] || []
                    const members: Array<Member> = rawMembers.map((item) => {
                        const member = new Member()

                        member.uuid = item[Packet.Common.USER] as string
                        member.name = item[Packet.Account.Keys.NAME] || ''
                        member.urole = item[Packet.Account.Keys.ROLE] || UserRole.STUDENT
                        member.avatar = item[Packet.Account.Keys.AVATAR] || Constants.DEF_PROFILE_PIC
                        member.grole = item[Packet.Group.Keys.ROLE]
                        member.timestamp = item[Packet.Common.TIMESTAMP]

                        return member
                    })

                    const isFromStarting = queued!.packet![Packet.Query.HANDLE] === 0

                    const onComplete = () => {
                        const mapItem = new Map()
                        members.forEach(x => mapItem.set(x.uuid, x))

                        queued?.resolve?.({ members: mapItem })
                    }

                    const channel = await ChannelDBOps.shared.getChannel(channelId)
                    if (channel === undefined) {
                        return onComplete()
                    }

                    if (channel.groupType !== GroupType.PRIVATE) {
                        //NOTE: keeping members in memory for public groups
                        return onComplete()
                    }

                    const updated = await GroupDBOps.shared.updateMembers(channel, members, isFromStarting)
                    if (!isFromStarting && updated && hasMore) {
                        this.fetchMembers(channelId, queryHandle)
                    }
                }
                break
            case Packet.Group.Types.LIST_GROUPS:
                {
                    const rawGroups: Array<RawPacket> = res[Packet.Group.Keys.GROUPS]

                    const groups: Array<Channel> = rawGroups.map((item) => {
                        const channel = new Channel()

                        channel.uuid = item[Packet.Common.CHANNEL]
                        channel.name = item[Packet.Group.Keys.NAME]
                        channel.avatar = item[Packet.Group.Keys.AVATAR]
                        channel.desc = item[Packet.Group.Keys.DESCRIPTION]
                        channel.type = ChannelType.GROUP
                        channel.groupType = item[Packet.Group.Keys.GROUP_TYPE]
                        channel.memberCount = item[Packet.Group.Keys.COUNT]
                        channel.isGroupAdmin = item[Packet.Group.Keys.IS_ADMIN] == Packet.Values.TRUE
                        channel.isGroupMember = item[Packet.Group.Keys.IS_MEMBER] === Packet.Values.TRUE

                        const receiptItem = item[Packet.Message.Keys.RECEIPT]

                        const info = {
                            delivery: receiptItem[Packet.Message.Receipt.DELIVERY_HANDLE],
                            read: receiptItem[Packet.Message.Receipt.READ_HANDLE],
                        }

                        const includeRecent = queued!.packet[Packet.Group.Keys.INCLUDE_RECENT] === Packet.Values.TRUE

                        if (includeRecent) {
                            const rawMembers: RawPacket[] = item[Packet.Group.Keys.MEMBERS]
                            rawMembers.forEach((rawMem) => {
                                const member = new Member()

                                member.uuid = rawMem[Packet.Common.USER]
                                member.name = rawMem[Packet.Account.Keys.NAME]
                                member.urole = rawMem[Packet.Account.Keys.ROLE]
                                member.avatar = rawMem[Packet.Account.Keys.AVATAR]
                                member.grole = rawMem[Packet.Group.Keys.ROLE]

                                channel.members.set(member.uuid, member)
                            })
                        }

                        MessageHandler.shared.addReceiptInfo(channel.uuid, info)

                        return channel
                    })

                    const includeRecent = queued!.packet[Packet.Group.Keys.INCLUDE_RECENT] === Packet.Values.TRUE

                    await GroupDBOps.shared.addFetchedGroups(groups, res[Packet.Common.TIMESTAMP])

                    if (!includeRecent &&
                        groups.length === 1 &&
                        groups[0].isGroupMember &&
                        groups[0].groupType === GroupType.PRIVATE
                    ) {
                        //Fetch all members and insert into db for private groups
                        this.fetchAllMembers(groups[0].uuid)
                    }

                    queued?.resolve?.()
                }
                break
            default:
                {
                    logger.leaveBreadcrumb("GroupHandler: unrecognized", {
                        type: res
                    })
                    logger.error("GroupHandler: unrecognized");
                }
                break
        }
    }
}