import Packet from "../enums/Packet";
import RawPacket from "../packets/RawPacket";
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient";
import UserRole from "../enums/UserRole";
import QueuedPacket from "../packets/QueuedPacket";
import UserDBOps from "../database/UserDBOps";
import { logger } from "../../utils/Logger";
import Config from "../models/Config";
import { asyncForEach } from "../../utils/general";
import ChannelUtils from "../utils/ChannelUtils";

export default class AccountHandler {

    static shared = new AccountHandler()

    fetchUserProfile = (uuid: string): Promise<{
        name: string,
        avatar: string,
        role: UserRole,
        status: number,
    }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.ACCOUNT
        pkt[Packet.Keys.SUB_TYPE] = Packet.Account.Types.PROFILE
        pkt[Packet.Common.USER] = uuid;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    getFriends = () => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.ACCOUNT
        pkt[Packet.Keys.SUB_TYPE] = Packet.Account.Types.FRIENDS
        pkt[Packet.Query.ORDER] = Packet.Query.ORDER_NEXT
        pkt[Packet.Query.HANDLE] = Config.shared.cached().friendSyncTimeHandle;
        pkt[Packet.Common.LIMIT] = 1000;
        pkt[Packet.Keys.ID] = uuidV4();

        if (Config.shared.isCare()) {
            logger.leaveBreadcrumb("Care user not allowed to use this api");
            return;
        }

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    makeFriend = (user: string): Promise<void> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.ACCOUNT
        pkt[Packet.Keys.SUB_TYPE] = Packet.Account.Types.MAKE_FRIEND;
        pkt[Packet.Common.USER] = user;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise<void>((resolve, reject) => {
            // if (Config.shared.isCare()) {
            //     return resolve()
            // }

            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    cancelInvite = (user: string): Promise<void> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.ACCOUNT
        pkt[Packet.Keys.SUB_TYPE] = Packet.Account.Types.INVITE_REM;
        pkt[Packet.Common.USER] = user;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise<void>((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    partFriend = (user: string): Promise<void> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.ACCOUNT
        pkt[Packet.Keys.SUB_TYPE] = Packet.Account.Types.PART_FRIEND;
        pkt[Packet.Common.USER] = user;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise<void>((resolve, reject) => {
            if (Config.shared.isCare()) {
                logger.leaveBreadcrumb("Care user not allowed to use this api");
                return reject("Only teacher/student can call this api");
            }

            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    blockUser = (user: string) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.ACCOUNT
        pkt[Packet.Keys.SUB_TYPE] = Packet.Account.Types.BLOCK;
        pkt[Packet.Common.USER] = user;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise<void>((resolve, reject) => {
            if (!Config.shared.isCare()) {
                logger.leaveBreadcrumb("Only care can call this api");
                return reject("Only care can call this api");
            }

            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    unBlockUser = (user: string) => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.ACCOUNT
        pkt[Packet.Keys.SUB_TYPE] = Packet.Account.Types.UNBLOCK;
        pkt[Packet.Common.USER] = user;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise<void>((resolve, reject) => {
            if (!Config.shared.isCare()) {
                logger.leaveBreadcrumb("Only care can call this api");
                return reject("Only care can call this api");
            }

            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    listBlocked = (): Promise<{ users: string[] }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.ACCOUNT
        pkt[Packet.Keys.SUB_TYPE] = Packet.Account.Types.LIST_BLOCKED;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise<{ users: string[] }>((resolve, reject) => {
            if (!Config.shared.isCare()) {
                logger.leaveBreadcrumb("Only care can call this api");
                return reject("Only care can call this api");
            }

            if (!ChatClient.shared.sendPacket(pkt, resolve)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.Account.Types.PROFILE:
                {
                    const user = res[Packet.Common.USER] as string;
                    const name = res[Packet.Account.Keys.NAME] as string;
                    const avatar = res[Packet.Account.Keys.AVATAR] as string;
                    const role = res[Packet.Account.Keys.ROLE] as UserRole;
                    const status = res[Packet.Account.Keys.STATUS] as number
                    const timestamp = res[Packet.Account.Keys.TIMESTAMP] as number

                    await UserDBOps.shared.updateUserProfile(user, name, avatar, role, status, timestamp)
                    queued?.resolve?.({ name, avatar, role, status });
                }
                break;
            case Packet.Account.Types.FRIENDS:
                {
                    const items: Array<RawPacket> = res[Packet.Account.Keys.LIST_CONTACTS];

                    const handle = res[Packet.Account.Keys.CONTACT_QUERY_HANDLE] as number;
                    const hasNext = res[Packet.Common.MORE] == Packet.Values.TRUE;

                    await asyncForEach(items, async (item) => {
                        const user = item[Packet.Common.USER] as string;
                        const status = item[Packet.Account.Keys.STATUS] as number;

                        const name = item[Packet.Account.Keys.NAME] as string;
                        const avatar = item[Packet.Account.Keys.AVATAR] as string;
                        const role = item[Packet.Account.Keys.ROLE] as UserRole;
                        const timestamp = item[Packet.Account.Keys.TIMESTAMP] as number

                        await UserDBOps.shared.updateUserProfile(user, name, avatar, role, status, timestamp)
                    })

                    Config.shared.updateFriendSyncTimeHandle(handle);

                    if (hasNext) {
                        this.getFriends();
                    }

                    queued?.resolve?.()
                }
                break;
            case Packet.Account.Types.MAKE_FRIEND:
                {
                    const friend: string = res[Packet.Common.USER] || res[Packet.Common.FROM]

                    const name = res[Packet.Account.Keys.NAME] as string;
                    const avatar = res[Packet.Account.Keys.AVATAR] as string;
                    const role = res[Packet.Account.Keys.ROLE] as UserRole;
                    const timestamp = res[Packet.Common.TIMESTAMP] as number

                    await UserDBOps.shared.updateUserProfile(friend, name, avatar, role, Packet.Values.TRUE, timestamp)
                    queued?.resolve?.();
                }
                break;
            case Packet.Account.Types.PART_FRIEND:
                {
                    const friend: string = res[Packet.Common.USER];
                    const timestamp: number = res[Packet.Common.TIMESTAMP];

                    await UserDBOps.shared.partFriend(friend, UserRole.UNKNOWN, timestamp)
                    queued?.resolve?.();
                }
                break;
            case Packet.Account.Types.BLOCK:
                {
                    const user: string = res[Packet.Common.USER];
                    const timestamp: number = res[Packet.Common.TIMESTAMP];

                    await UserDBOps.shared.partFriend(user, UserRole.UNKNOWN, timestamp)
                    queued?.resolve?.({ user })
                }
                break;
            case Packet.Account.Types.UNBLOCK:
                {
                    const user = res[Packet.Common.USER];
                    queued?.resolve?.({ user })
                }
                break;
            case Packet.Account.Types.LIST_BLOCKED:
                {
                    const users = res[Packet.Common.USER_LIST];
                    queued?.resolve?.({ users })
                }
                break;
            case Packet.Account.Types.INVITE_REM:
                {
                    const user = queued!.packet[Packet.Common.USER]
                    const channelID = ChannelUtils.makeChannel(user, UserRole.STUDENT)
                    ChatClient.shared.removeChannelLoadedCache(channelID)

                    queued?.resolve?.()
                }
                break;
            default:
                {
                    logger.leaveBreadcrumb("AccountHandler: unrecognized", {
                        type: res
                    })
                    logger.error("AccountHandler: unrecognized");
                }
                break;
        }
    }
}