import Packet from "../enums/Packet";
import RawPacket from "../packets/RawPacket";
import { v4 as uuidV4 } from 'uuid';
import UserRole from "../enums/UserRole";
import QueuedPacket from "../packets/QueuedPacket";
import { logger } from "../../utils/Logger";

import MessageType from "../enums/MessageType";
import ChatClient from "./ChatClient";
import ResponseCode from "../enums/ResponseCode";
import Message from "../models/Message";
import { CareTicket } from "../packets/CareTicket";
import MessageHandler from "./MessageHandler";
import ChannelUtils from "../utils/ChannelUtils";

export default class CareHandler {

    static shared = new CareHandler()

    private constructor() { }

    closeP2PCareTicket = (user: string, timeHandle: number): Promise<{ success: boolean, code: ResponseCode }> => {
        let pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.CARE
        pkt[Packet.Keys.SUB_TYPE] = Packet.Care.Types.CLOSE_TICKET
        pkt[Packet.Message.Keys.MSG_TYPE] = MessageType.P2P;
        pkt[Packet.Common.USER] = user;
        pkt[Packet.Message.Keys.TIME_HANDLE] = timeHandle;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    fetchP2PCareTickets = (role: UserRole): Promise<{
        tickets: Array<{
            user: string,
            name: string,
            role: UserRole,
            avatar: string,
            message: Message,
            unread: number
        }>
    }> => {
        let pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.CARE
        pkt[Packet.Keys.SUB_TYPE] = Packet.Care.Types.LIST_TICKETS
        pkt[Packet.Account.Keys.ROLE] = role;
        pkt[Packet.Message.Keys.MSG_TYPE] = MessageType.P2P;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    };

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.Care.Types.CLOSE_TICKET:
                {
                    const rc = res[Packet.Keys.RESPONSE_CODE] as number;
                    queued?.resolve?.({ success: (rc == ResponseCode.OK), code: rc });
                }
                break
            case Packet.Care.Types.LIST_TICKETS:
                {
                    const rawMsgItems: Array<RawPacket> = res[Packet.Message.Keys.MESSAGES] || [];
                    const rawContactItems: Array<RawPacket> = res[Packet.Account.Keys.LIST_CONTACTS] || [];

                    const mContacts = new Map<string, {
                        user: string,
                        name: string,
                        role: UserRole,
                        avatar: string
                    }>()

                    rawContactItems.forEach((item) => {
                        const userID = item[Packet.Common.USER] as string;

                        mContacts.set(userID, {
                            user: userID,
                            name: item[Packet.Account.Keys.NAME],
                            role: item[Packet.Account.Keys.ROLE],
                            avatar: item[Packet.Account.Keys.AVATAR]
                        });
                    })

                    let tickets: Array<CareTicket> = rawMsgItems.map(MessageHandler.shared.convertWireToMessage)
                        .map((item: { message: Message, unread: number }) => {

                            let otherUser = ChannelUtils.otherChannelUser(item.message.channel, Packet.Common.CARE);

                            if (!!!otherUser) {
                                otherUser = item.message.from === Packet.Common.CARE ? item.message.to : item.message.from;
                            }

                            return { otherUser, message: item.message, unread: item.unread }
                        }).filter((item) => {
                            return mContacts.has(item.otherUser)
                        }).map((item) => {
                            const contact = mContacts.get(item.otherUser)!

                            return { ...contact, message: item.message, unread: item.unread }
                        })

                    tickets = this.sortTickets(tickets);

                    queued?.resolve?.({ tickets: tickets });
                }
                break;
            default:
                {
                    logger.leaveBreadcrumb("CareHandler: unrecognized", {
                        type: res
                    })
                    logger.error("CareHandler: unrecognized");
                }
                break;
        }
    }

    sortTickets = (tickets: Array<{
        user: string,
        name: string,
        role: UserRole,
        avatar: string,
        message: Message,
        unread: number
    }>): Array<{
        user: string,
        name: string,
        role: UserRole,
        avatar: string,
        message: Message,
        unread: number
    }> => {

        tickets.sort((a, b) => {
            if (b.unread > a.unread) {
                return 1
            }

            if (a.unread > b.unread) {
                return -1
            }

            if (b.message.timeHandle > a.message.timeHandle) {
                return 1
            }

            if (a.message.timeHandle > b.message.timeHandle) {
                return -1
            }

            return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        })

        return tickets;
    }
}