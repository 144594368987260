import React, { useState, useEffect, useRef } from 'react';
import { post_api } from '../../../../redux/api_funcs';
import { get_api_url } from '../../../../utils/urls';

function SearchStudent({ onStudentUpdate, handleStudentErrorMSG }) {
  const [loading, setLoading] = useState(false);
  const [searchStudent, setsearchStudent] = useState('');
  const [filteredStudent, setFilteredStudent] = useState('');
  const [studentData, setStudentData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const dropDownRef = useRef(null);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setFilteredStudent(searchStudent);
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchStudent]);

  useEffect(() => {
    const getStudentList = async () => {
      if (filteredStudent.length >= 3) {
        setLoading(true);
        const url = get_api_url('search_students_including_queue');
        const payload = { search_param: filteredStudent };
        try {
          const result = await post_api(url, payload, true, 'none');
          setStudentData(result);
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
        }
      }
    };
    getStudentList();
  }, [filteredStudent]);

  const onHandleInput = (value) => {
    handleStudentErrorMSG();
    setShowDropdown(true);
    setsearchStudent(value);
  };

  const handleStudentClick = (firstName, studentUUID) => {
    setShowDropdown(false);
    setsearchStudent(firstName);
    onStudentUpdate(studentUUID);
  };

  // Outside Click close Drop Down Start
  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      window.addEventListener('click', handleClickOutside);
    } else {
      window.removeEventListener('click', handleClickOutside);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [showDropdown]);
  // Outside Click close Drop Down End

  return (
    <div className="relative w-250px">
      <div className="mb-1">Student</div>
      <input
        style={{ margin: '0' }}
        type="text"
        placeholder="Search student"
        value={searchStudent}
        onChange={(e) => onHandleInput(e.target.value)}
      />
      {showDropdown ? (
        <div ref={dropDownRef}>
          {studentData?.data?.students?.length > 0 && searchStudent !== '' ? (
            <div className="bg-white w-auto rounded-xl border-2 border-borderColor z-10 top-20 left-0 right-0 py-4 absolute flex flex-col items-start max-h-400px overflow-y-scroll overflow-x-hidden">
              {loading ? (
                <div className="px-4">Searching...</div>
              ) : (
                studentData?.data?.students.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleStudentClick(item.first_name, item.student_uuid)}
                      className="px-4 w-full hover:bg-gray-200 cursor-pointer mb-1"
                    >
                      <p className="m-0">
                        {item.first_name}&nbsp;{item.last_name}
                      </p>
                      <p className="m-0 text-mytLightGray">{item.email}</p>
                    </div>
                  ))
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default SearchStudent;
