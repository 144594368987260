import { logger } from "../../utils/Logger";
import ConnectionState from "../enums/ConnectionState";
import { RecoType } from "../enums/RecoType";
import Member from "../models/Member";
import Message from "../models/Message";
import User from "../models/User";
import { RecoUser } from "../packets/RecoItem";
import RelayItem from "../packets/RelayItem";
import { SMSChannelPkt } from "../packets/SMSChannel";
import { RelayCallbacks } from "./relay";

export type ConnectionListener = (connectionState: ConnectionState) => void
export type SMSDataHandler = (item: SMSChannelPkt) => void
export type RecoHandler = (users: Map<string, RecoUser>, hasMore: boolean, type: RecoType) => void
export type PresenceListener = (user: string, status: string) => void
export type ProgressListener = (message: Message, mediaIdx: number, progress: number) => void
export type MessageListener = (message: Message) => void
export type TypingListener = (channelId: string, from: User | Member, typing: boolean) => void

export class CallbackHandler {

    public static shared = new CallbackHandler()

    private constructor() { }

    private connectionListeners = new Array<ConnectionListener>()

    registerConnectionListener = (handler: ConnectionListener) => {
        this.connectionListeners.push(handler);
    }

    unregisterConnectionListener = (handler: ConnectionListener) => {
        this.connectionListeners = this.connectionListeners.filter((x) => x !== handler);
    }

    callOnConnectionStateChanged = async (state: ConnectionState) => {
        logger.leaveBreadcrumb("callOnConnectionStateChanged", {
            state: state
        }, 'state')

        this.connectionListeners.forEach((handler) => {
            handler(state)
        })
    }

    // typing 
    private typingListeners = new Array<TypingListener>()

    registerTypingListener = (handler: TypingListener) => {
        this.typingListeners.push(handler)
    }

    unregisterTypingListener = (handler: TypingListener) => {
        this.typingListeners = this.typingListeners.filter((x) => x !== handler);
    }

    callOnTypingStateChanged = async (channelId: string, from: User | Member, typing: boolean) => {
        this.typingListeners.forEach((handler) => {
            handler(channelId, from, typing)
        })
    }

    // sms
    private smsHandlers = new Array<SMSDataHandler>()

    registerSMSHandler = (handler: SMSDataHandler) => {
        this.smsHandlers.push(handler)
    }

    unregisterSMSHandler = (handler: SMSDataHandler) => {
        this.smsHandlers = this.smsHandlers.filter((x) => x !== handler);
    }

    callOnSMSChannelData = async (item: SMSChannelPkt) => {
        this.smsHandlers.forEach((handler) => {
            handler(item)
        })
    }

    //reco handler
    private recoHandler = new Array<RecoHandler>()

    registerRecoHandler = (handler: RecoHandler) => {
        this.recoHandler.push(handler)
    }

    unregisterRecoHandler = (handler: RecoHandler) => {
        this.recoHandler = this.recoHandler.filter((x) => x !== handler);
    }

    callOnRecoData = async (users: Map<string, RecoUser>, hasMore: boolean, type: RecoType) => {
        this.recoHandler.forEach((handler) => {
            handler(users, hasMore, type)
        })
    }

    //
    private relayHandler = new Array<RelayCallbacks>()

    registerRelayHandler = (handler: RelayCallbacks) => {
        this.relayHandler.push(handler)
    }

    unregisterRelayHandler = (handler: RelayCallbacks) => {
        this.relayHandler = this.relayHandler.filter((x) => x !== handler);
    }

    callOnRelayData = async (items: Array<RelayItem>, hasMore: boolean) => {
        this.relayHandler.forEach((handler) => {
            handler.onRelayData?.(items, hasMore)
        })
    }

    private presenceListeners = new Array<PresenceListener>()

    registerPresenceListener = (handler: PresenceListener) => {
        this.presenceListeners.push(handler)
    }

    unregisterPresenceListener = (handler: PresenceListener) => {
        this.presenceListeners = this.presenceListeners.filter((x) => x !== handler);
    }

    callOnPresenceReceived = async (uuid: string, status: string) => {
        this.presenceListeners.forEach((x) => x(uuid, status))
    }

    ///

    private progressListeners = new Array<ProgressListener>()

    registerUploadProgressListener = (handler: ProgressListener) => {
        this.progressListeners.push(handler)
    }

    unregisterUploadProgressListener = (handler: ProgressListener) => {
        this.progressListeners = this.progressListeners.filter((x) => x !== handler);
    }

    callOnProgressUpdated = async (message: Message, mediaIdx: number, progress: number) => {
        this.progressListeners.forEach((x) => x(message, mediaIdx, progress))
    }

    private messageListeners = new Array<MessageListener>()

    registerOnMessageAddedListener = (handler: MessageListener) => {
        this.messageListeners.push(handler)
    }

    unregisterOnMessageAddedListener = (handler: MessageListener) => {
        this.messageListeners = this.messageListeners.filter((x) => x !== handler);
    }

    callOnMessageAdded = async (message: Message) => {
        this.messageListeners.forEach((x) => x(message))
    }
}