import UserRole from "../enums/UserRole";
import User from "../models/User";

const UserUtils = {
    IsRoleCare: (role: UserRole): boolean => {
        return role == UserRole.CARE
    },
    IsCare: (user: User): boolean => {
        return user.role == UserRole.CARE
    }
}

export default UserUtils;