import { logger } from "../../utils/Logger"
import Packet from "../enums/Packet"
import QueuedPacket from "../packets/QueuedPacket"
import RawPacket from "../packets/RawPacket"
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient";

export default class PingHandler {

    static shared = new PingHandler()

    private pingTimer?: number
    private pongTimer?: number

    private config = {
        pingInterval: 16, //seconds
        pongTimeoutInterval: 5, //seconds
    };

    clear = () => {
        clearTimeout(this.pingTimer);
        clearTimeout(this.pongTimer);
    }

    restartPingTimer = () => {
        this.clear()
        this.pingTimer = window.setTimeout(this.sendPing, this.config.pingInterval * 1000);
    };

    resetPongTimeoutTimer = () => {
        clearTimeout(this.pongTimer);
        this.pongTimer = window.setTimeout(this.onPongTimeOut, this.config.pongTimeoutInterval * 1000);
    };

    onPongTimeOut = () => {
        logger.debug("PingHandler: ping timeout..");
        ChatClient.shared.closeConnection();
    };

    sendPing = () => {
        this.resetPongTimeoutTimer();

        let pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.PING;
        pkt[Packet.Keys.ID] = uuidV4();

        return ChatClient.shared.sendPacket(pkt);
    };

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        logger.debug("pong: connection alive");
    }
}