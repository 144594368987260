import { defaultAuthInfo, getAuthUserInfo, getAuthUserInfoOrDefault } from "../../network/cookie";
import Constants from "../config/Constants";
import MessageType from "../enums/MessageType";
import UserRole from "../enums/UserRole";
import { Mention } from "./Message";
import { db } from "../database/MytChatDB";
import { logger } from "../../utils/Logger";
import Packet from "../enums/Packet";

type OnDBInitCallback = () => {}

export default class Config {

    static shared = new Config()

    private currentChannelId = ""
    private isDBInitialized = false
    private onDBInitCallback?: OnDBInitCallback = undefined

    private cache = {
        fcmPushToken: "",
        syncFcmPushToken: false,
        friendSyncTimeHandle: 0,
        lastMessageP2PTimeHandle: 0,
        lastMessageGroupTimeHandle: 0,
        relaySyncTimeHandle: 0,
        groupSyncTimeHandle: 0,
        name: "",
        avatar: "",
        authInfo: defaultAuthInfo(),
        lastChannelOpened: "",
        initialSynced: false,
    }

    cached = () => {
        return this.cache
    }

    isStudent = (): boolean => {
        return this.cache.authInfo.role === UserRole.STUDENT
    }

    isTeacher = (): boolean => {
        return this.cache.authInfo.role === UserRole.TEACHER
    }

    isCare = (): boolean => {
        return this.cache.authInfo.role === UserRole.CARE
    }

    myUUID = (): string => {
        return this.cache.authInfo.uuid!
    }

    setProfile = (profile: {
        name: string,
        avatar: string,
        // role: UserRole,
    }) => {
        this.cache.name = profile.name
        this.cache.avatar = profile.avatar

        localStorage.setItem(Constants.SingletonUserConfigKey, JSON.stringify(this.cache))
    }

    private constructor() {
        if (import.meta.env.SSR) {
            return
        }

        const stored = localStorage.getItem(Constants.SingletonUserConfigKey)

        if (stored !== null) {
            this.cache = JSON.parse(stored)
        }

        const authInfo = getAuthUserInfoOrDefault()

        if (this.cache.authInfo.role === authInfo.role &&
            this.cache.authInfo.uuid !== authInfo.uuid) {
            logger.error("ERROR: DB Tables clearing ....")
            db.clearTables().then(this.setDBInitDone)
        } else {
            this.setDBInitDone()
        }

        this.cache.authInfo = authInfo
    }

    setOnDBInitCallback = (callback: OnDBInitCallback) => {
        if (this.isDBInitialized) {
            callback()
        } else {
            this.onDBInitCallback = callback
        }
    }

    private setDBInitDone = () => {
        logger.debug("Config->dbinit->done")

        this.isDBInitialized = true
        this.onDBInitCallback?.()
        this.onDBInitCallback = undefined
    }

    initialize = () => {
        this.cache.authInfo = getAuthUserInfo()
    }

    clear = () => {
        localStorage.removeItem(Constants.SingletonUserConfigKey)
        localStorage.removeItem(Constants.CachedMessagesKey)
        localStorage.removeItem(Constants.QueuedTasksKey)
    }

    lastMessageTimeHandle = (messageType: MessageType): number => {
        return messageType === MessageType.P2P ?
            this.cache.lastMessageP2PTimeHandle : this.cache.lastMessageGroupTimeHandle
    }

    updateRelaySyncTimeHandle = (handle: number) => {
        if (this.cache.relaySyncTimeHandle > handle) {
            return
        }

        this.cache.relaySyncTimeHandle = handle

        localStorage.setItem(Constants.SingletonUserConfigKey, JSON.stringify(this.cache))
    }

    updateLastMessageTimeHandle = (handle: number, messageType: MessageType) => {
        let key = messageType === MessageType.P2P ? 'lastMessageP2PTimeHandle' : 'lastMessageGroupTimeHandle'

        if (this.cache[key] > handle) {
            return
        }

        this.cache[key] = handle
        localStorage.setItem(Constants.SingletonUserConfigKey, JSON.stringify(this.cache))
    }

    updateFriendSyncTimeHandle = (handle: number) => {
        if (this.cache.friendSyncTimeHandle > handle) {
            return
        }

        this.cache.friendSyncTimeHandle = handle

        localStorage.setItem(Constants.SingletonUserConfigKey, JSON.stringify(this.cache))
    }

    updatePushToken = (token: string) => {
        this.cache.syncFcmPushToken = this.cache.fcmPushToken !== token
        this.cache.fcmPushToken = token

        localStorage.setItem(Constants.SingletonUserConfigKey, JSON.stringify(this.cache))
    }

    markRegistered = (token: string) => {
        this.cache.syncFcmPushToken = false
        this.cache.fcmPushToken = token

        localStorage.setItem(Constants.SingletonUserConfigKey, JSON.stringify(this.cache))
    }

    currentChannel = () => {
        return this.currentChannelId
    }

    setActiveChannel = (uuid: string) => {
        this.currentChannelId = uuid

        if (uuid) {
            this.cache.lastChannelOpened = uuid
            localStorage.setItem(Constants.SingletonUserConfigKey, JSON.stringify(this.cache))
        }
    }

    isInitialSynced = () => {
        const decoded = JSON.parse(localStorage.getItem(Constants.SingletonUserConfigKey) || '{}')
        return decoded['initialSynced'] || false
    }

    setInitialSynced = () => {
        const decoded = JSON.parse(localStorage.getItem(Constants.SingletonUserConfigKey) || '{}')
        decoded['initialSynced'] = true
        localStorage.setItem(Constants.SingletonUserConfigKey, JSON.stringify(decoded))
    }

    cachedMessage = (uuid: string): {
        text: string,
        mentions: Array<Mention>
    } => {
        const decoded = JSON.parse(localStorage.getItem(Constants.CachedMessagesKey) || '{}')

        return decoded[uuid] || {
            text: '',
            mentions: []
        }
    }

    cacheTypedMessage = (uuid: string, text: string, mentions: Array<Mention>) => {
        const decoded = JSON.parse(localStorage.getItem(Constants.CachedMessagesKey) || '{}')

        decoded[uuid] = {
            text: text,
            mentions: mentions
        }

        localStorage.setItem(Constants.CachedMessagesKey, JSON.stringify(decoded))
    }
}
