const dev_domain = "gstg1.myyogateacher.com";
const prod_domain = "gapi.myyogateacher.com";

const urls = () => {
    let domain = "";
    let client_domain = ""

    switch (import.meta.env.VITE_APP_STAGE) {
        case "production":
            domain = prod_domain;
            break;
        default:
            domain = dev_domain;
            break;
    }

    return {
        wschat: `wss://${domain}/v1.0/ws`,
        total_unread: `https://${domain}/v1.0/messages/unread/total`,
        student_details: `https://${domain}/student/api/account_info`,
        file_upload: `https://${domain}/v1.0/upload/chat`,
        url_shortner: `https://${domain}/v2/url_shortner`,
        session_image_html: `https://${domain}/v2/schedule_session/student/session_image_html`,
        student_profile_details: `https://${domain}/v2/student_profile/details`,
        teacher_profile_details: `https://${domain}/v2/teacher/profile/details`,
        home_data: `https://${domain}/v2/home/data`,
        get_student_notifications: `https://${domain}/student/api/get_notifications`,
        student_update_notification_read_status:
            `https://${domain}/student/api/update_notification_read_status`,
        teacher_details: `https://${domain}/teacher/api/teachers_details`,
        teacher_notification_summary:
            `https://${domain}/teacher/api/get_new_notifications_summary`,
        teacher_get_notifications: `https://${domain}/teacher/api/get_notifications`,
        teacher_update_notification_read_status:
            `https://${domain}/teacher/api/update_notification_read_status`,
        teacher_get_all_upload_image: `https://${domain}/v2/schedule_session/teacher/session_images/listall`
    }
}

export default urls();