enum ResponseCode {
    OK = 200,
    CREATED = 201,
    NO_CONTENT = 204,
    PARTIAL_CONTENT = 206,

    BAD_REQUEST = 400,
    AUTH_ERR = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    CONFLICT = 409,
    INTERNAL = 500
};

export default ResponseCode;