import RawPacket from "./RawPacket";

export default class QueuedPacket {
    packet: RawPacket
    resolve?: (value?: any) => void

    constructor(packet: RawPacket, resolve?: (value?: any) => void) {
        this.packet = packet;
        this.resolve = resolve;
    }
}