import React, { useEffect, useState, useRef } from 'react';
import { get_api_url } from '../../../../utils/urls';
import { post_api } from '../../../../redux/api_funcs';

function SearchTeacher({ onTeacherUpdate, handleTeacherErrorMSG }) {
  const [loading, setLoading] = useState(false);
  const [teacherList, setTeacherList] = useState([]);
  const [searchTeacher, setSearchTeacher] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropDownRef = useRef(null);

  useEffect(() => {
    const getTeacherList = async () => {
      try {
        setLoading(true);
        const path = 'get_teachers_list';
        const url = get_api_url(path);
        const response = await post_api(url, null, true);
        const teacherListData = response?.data?.teachers;
        setTeacherList(teacherListData);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };
    getTeacherList();
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedSearch(searchTeacher);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [searchTeacher]);

  const onHandleInput = (value) => {
    handleTeacherErrorMSG();
    setSearchTeacher(value);
    setShowDropdown(true);
  };

  const handleTeacherSelected = (teacherName, teacherUUID) => {
    setSearchTeacher(teacherName);
    onTeacherUpdate(teacherUUID);
    setShowDropdown(false);
  };

  // Outside Click close Drop Down Start
  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      window.addEventListener('click', handleClickOutside);
    } else {
      window.removeEventListener('click', handleClickOutside);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [showDropdown]);
  // Outside Click close Drop Down End

  return (
    <div className="relative w-250px">
      <div className="mb-1">Teacher</div>
      <input
        style={{ margin: '0' }}
        type="text"
        placeholder="Search teacher"
        value={searchTeacher}
        onChange={(e) => onHandleInput(e.target.value)}
      />
      {showDropdown && teacherList?.length > 0 && debouncedSearch !== '' && (
        <div
          ref={dropDownRef}
          className="w-auto bg-white rounded-xl border-2 border-borderColor z-10 top-20 left-0 right-0 py-4 absolute flex flex-col items-start max-h-400px overflow-y-scroll overflow-x-hidden"
        >
          {loading ? (
            <div className="px-4">Searching...</div>
          ) : (
            teacherList
              .filter((teacher) =>
                teacher.first_name.toLowerCase().includes(debouncedSearch.toLowerCase())
              )
              .map((teacher, index) => (
                <div
                  key={index}
                  onClick={() => handleTeacherSelected(teacher.first_name, teacher.uuid)}
                  className="px-4 w-full hover:bg-gray-200 cursor-pointer mb-1"
                >
                  <p className="m-0">
                    {teacher.first_name}&nbsp;{teacher.last_name}
                  </p>
                  <p className="m-0 text-mytLightGray">{teacher.email}</p>
                </div>
              ))
          )}
        </div>
      )}
    </div>
  );
}

export default SearchTeacher;
