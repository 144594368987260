import Constants from "../config/Constants";
import UserRole from "../enums/UserRole";

export default class User {

    uuid: string = "";

    name: string = "";
    avatar: string = Constants.DEF_PROFILE_PIC;
    role: UserRole = UserRole.UNKNOWN;

    timestamp: number = 0 //last update time in millis
}