import Dexie, { Table } from "dexie";
import Channel from "../models/Channel";
import User from "../models/User";
import Config from "../models/Config";
import Constants from "../config/Constants";
import Message from "../models/Message";
import { logger } from "../../utils/Logger";

Dexie.debug = import.meta.env.VITE_APP_STAGE !== 'production'

class MytChatDB extends Dexie {

    channel!: Table<Channel, string>;
    message!: Table<Message, string>;
    user!: Table<User, string>;

    constructor() {
        super(Constants.DATABASE_NAME);

        this.version(1).stores({
            channel: '&uuid, type, unread, isInviteActionPending, isCommunityGroup', // Primary key and indexed props
            message: '&id, state, timeHandle, [channel+state], [channel+type], [channel+timeHandle]',
            user: '&uuid'
        });

        this.channel.mapToClass(Channel);
        this.message.mapToClass(Message);
        this.user.mapToClass(User);
    }

    clear = async () => {
        Config.shared.clear()

        await this.clearTables()

        await db.delete()
        logger.leaveBreadcrumb('database succussfully cleared.');
    }

    clearTables = async () => {
        const promises = [
            this.channel.clear(),
            this.message.clear(),
            this.user.clear(),
        ]

        await Promise.all(promises);
        logger.leaveBreadcrumb('all tables succussfully cleared.');
    }
}

export const db = new MytChatDB();