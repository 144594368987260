import InitHandler from '../chat/network/InitHandler';
import ChannelUtils from '../chat/utils/ChannelUtils';
import UserRole from '../chat/enums/UserRole';
import { isDesktop } from "react-device-detect"
import Constants from "../chat/config/Constants";
import { v4 as uuidv4 } from "uuid";
import ChatClient from "../chat/network/ChatClient";
import urls from "../network/urls";
import { getAuthToken } from "../network/cookie";
import { useEffect } from "react";
import { logger } from '../utils/Logger';
import PubSub from 'pubsub-js'

const windowTarget = "myt-chat-window"

export enum QueuedType {
    sharePhotos = "share_photos"
}

export type QueuedData = {
    student_uuid: string,
    uuid: string
    signed_url: string
    filename: string
}

type QueuedCacheData = {
    pending_actions: Array<{
        uuid: string,
        type: QueuedType,
        data: QueuedData
    }>
}

const EventUnread = "chat.messages.unread"
const EventChatTabState = 'chat.window.tab.state'

const defaultUnreadSyncTimeout = 5 * 60 * 1000 // 5 minutes
const fastUnreadSyncTimeout = 1 * 60 * 1000 // 60 seconds

class ChatService {

    static shared = new ChatService()

    private windowRef: Window | null

    private broadcastChannel = new BroadcastChannel('com.myyogateacher.chat.channel');

    private isDataReceivedViaBroadcast = false
    private unreadLoadTimeoutRef: number | undefined
    private unread = 0;

    private setUnread = (updated: number): number => {
        const timeoutVal = updated > this.unread ? fastUnreadSyncTimeout : defaultUnreadSyncTimeout;

        this.unread = updated;
        PubSub.publish(Constants.PubSubUnreadTotalCount, { unread: updated });

        return timeoutVal;
    };

    private loadUnreadCount = async () => {
        if (this.isDataReceivedViaBroadcast) {
            window.clearTimeout(this.unreadLoadTimeoutRef)
            return
        }

        const unreadData = await fetch(urls.total_unread, {
            headers: {
                'Authorization': "Bearer " + getAuthToken(),
            },
        }).then((res) => res.json())

        const loadTimeout = this.setUnread(unreadData.unread)
        this.unreadLoadTimeoutRef = window.setTimeout(this.loadUnreadCount, loadTimeout)
    }

    private constructor() {
        this.broadcastChannel.onmessage = (event: MessageEvent) => {
            event.preventDefault();

            switch (event.data.type) {
                case EventUnread:
                    this.isDataReceivedViaBroadcast = true;
                    this.setUnread(event.data.unread || 0);
                    break;
                case EventChatTabState:
                    this.isDataReceivedViaBroadcast = event.data.opened;

                    if (event.data.opened) {
                        window.clearTimeout(this.unreadLoadTimeoutRef);
                    } else {
                        this.unreadLoadTimeoutRef = window.setTimeout(this.loadUnreadCount, defaultUnreadSyncTimeout);
                    }
                    break;
                default:
                    break;
            }
        }

        this.broadcastChannel.onmessageerror = (event: MessageEvent) => {
            logger.leaveBreadcrumb('ChatService.broadcastChannel.onmessageerror', {
                data: event
            })
        }
    }

    openGroupChat = (
        uuid: string,
        newWindow: boolean = false,
    ) => {
        if (newWindow || isDesktop) {
            this.windowRef = window.open(`/chat?channel=${uuid}&type=grp`, windowTarget)
        } else {
            window.location.href = `/chat?channel=${uuid}&type=grp`
        }
    }

    openCareChat = (
        newWindow: boolean = false,
    ) => {
        const channelId = ChannelUtils.makeChannel(Constants.CareUser, UserRole.CARE)
        if (newWindow || isDesktop) {
            this.windowRef = window.open(`/chat?channel=${channelId}&type=p2p`, windowTarget)
        } else {
            window.location.href = `/chat?channel=${channelId}&type=p2p`
        }
    }

    openP2PChat = (
        user: string,
        role: UserRole = UserRole.UNKNOWN,
        newWindow: boolean = false
    ) => {
        const channelId = ChannelUtils.makeChannel(user, role)
        if (newWindow || isDesktop) {
            this.windowRef = window.open(`/chat?channel=${channelId}&type=p2p`, windowTarget)
        } else {
            window.location.href = `/chat?channel=${channelId}&type=p2p`
        }
    }

    openChatWindow = (
        newWindow: boolean = false
    ) => {
        if (newWindow || isDesktop) {
            this.windowRef = window.open(`/chat`, windowTarget)
        } else {
            window.location.href = `/chat`
        }
    }

    logout = async () => {
        this.windowRef?.close()
        InitHandler.shared.logout()
    }

    publishChatWindowState = (opened: boolean) => {
        logger.debug('publishChatWindowState', opened);

        this.broadcastChannel.postMessage({
            type: EventChatTabState,
            opened: opened
        })
    }

    updateUnreadCount = (unread: number) => {
        logger.debug('updateUnreadCount', unread);

        this.broadcastChannel.postMessage({
            type: EventUnread,
            unread: unread
        })
    }

    useTotalUnreadCount = () => {
        useEffect(() => {
            this.loadUnreadCount()

            return () => {
                window.clearTimeout(this.unreadLoadTimeoutRef)
                this.broadcastChannel.onmessage = null
                this.broadcastChannel.onmessageerror = null;
            }
        }, [])

        return this.unread;
    }

    setQueuedTask = (type: QueuedType, data: QueuedData) => {
        const encoded = localStorage.getItem(Constants.QueuedTasksKey) || '{"pending_actions": []}'
        let decoded: QueuedCacheData = JSON.parse(encoded)
        decoded.pending_actions.push({
            uuid: uuidv4(),
            type: type,
            data: data
        })
        localStorage.setItem(Constants.QueuedTasksKey, JSON.stringify(decoded));
    }

    markQueuedTaskProcessed = (uuid: string) => {
        const encoded = localStorage.getItem(Constants.QueuedTasksKey) || '{"pending_actions": []}'
        let decoded: QueuedCacheData = JSON.parse(encoded)
        decoded.pending_actions = decoded.pending_actions.filter((x) => x.uuid !== uuid)
        localStorage.setItem(Constants.QueuedTasksKey, JSON.stringify(decoded));
    }

    getPendingQueuedTask = () => {
        const encoded = localStorage.getItem(Constants.QueuedTasksKey) || '{"pending_actions": []}'
        const decoded: QueuedCacheData = JSON.parse(encoded)
        return decoded.pending_actions[0]
    }

    initialize = () => {
        ChatClient.shared.initialize()
    }
}

export default ChatService.shared;