import Dexie from "dexie";
import { asyncForEach } from "../../utils/general";
import Constants from "../config/Constants";
import ChannelType from "../enums/ChannelType";
import GroupCreateMode from "../enums/GroupCreateMode";
import GroupRole from "../enums/GroupRole";
import GroupType from "../enums/GroupType";
import MessageState from "../enums/MessageState";
import Packet from "../enums/Packet";
import Channel from "../models/Channel";
import Config from "../models/Config";
import Member from "../models/Member";
import ChatClient from "../network/ChatClient";
import RawPacket from "../packets/RawPacket";
import { db } from "./MytChatDB";
import PubSub from 'pubsub-js';
import { Sender } from "../models/Message";

export type InvitedGroupInfo = {
    text: string
    channel: string
    timestamp: number
    name: string
    avatar: string
    grouptype: GroupType
    role: GroupRole
    invitedBy: Sender
}

export default class GroupDBOps {

    static shared = new GroupDBOps()

    private constructor() { }

    updateChannelMemberCount = async (
        channelId: string,
        memberCount: number
    ) => {
        if (memberCount < 0) return

        const groupItem = await db.channel.get(channelId)

        if (groupItem === undefined) return;

        const updates: RawPacket = {
            memberCount: memberCount
        }

        await db.channel.update(channelId, updates)
    }

    updateMember = async (
        channelId: string,
        member: Member,
        joined: boolean = false
    ) => {
        const groupItem = await db.channel.get(channelId)

        if (groupItem === undefined) return;

        const dbMember = groupItem.members.get(member.uuid);

        if (dbMember != undefined && dbMember.timestamp >= member.timestamp) return

        groupItem.members.set(member.uuid, member)

        const updates: RawPacket = {
            members: groupItem.members,
        }

        if (joined && member.uuid !== Config.shared.myUUID()) {
            // TODO: show toast maybe?
            // other user joined increment the count
            // for user who joined, take value from server in push profile packet
        }

        if (member.uuid === Config.shared.myUUID()) {
            updates.isGroupMember = member.grole !== GroupRole.NONE
            updates.isGroupAdmin = member.grole === GroupRole.ADMIN
        }

        await db.channel.update(channelId, updates)
    }

    removeGroupMember = async (
        channelId: string,
        memberId: string,
        timestamp: number,
    ) => {
        const groupItem = await db.channel.get(channelId)

        if (groupItem === undefined) return;

        const member = groupItem.members.get(memberId);

        if (member == undefined || member.timestamp > timestamp) return

        if (memberId === Config.shared.myUUID() && groupItem.groupType === GroupType.PRIVATE) {
            if (Config.shared.currentChannel() === channelId) {
                PubSub.publish(Constants.PubSubChannelCloseTopic, false)
            }
            await db.message.where({ channel: channelId }).delete()
            await db.channel.delete(channelId)
            return
        }

        const updates: RawPacket = {
            members: groupItem.members,
        }

        member.grole = GroupRole.NONE
        member.timestamp = timestamp

        updates.members.set(memberId, member)

        if (memberId === Config.shared.myUUID()) {
            updates.isGroupAdmin = false
            updates.isGroupMember = false
        }

        await db.channel.update(channelId, updates)
    }

    addCommunityGroups = async (groups: Array<{
        agent_id: string,
        channel_id: string,
        slug: string,
        thumbnail: string,
        title: string
    }>): Promise<Array<string>> => {

        const channels = new Array<string>()

        await asyncForEach(groups, async (item) => {
            const channelItem = await db.channel.get(item.channel_id)

            if (channelItem !== undefined) return

            const channel = new Channel()

            channel.uuid = item.channel_id
            channel.name = item.title
            channel.avatar = item.thumbnail
            channel.type = ChannelType.GROUP
            channel.groupType = GroupType.PUBLIC
            channel.isCommunityGroup = Packet.Values.TRUE

            await db.channel.add(channel)

            channels.push(item.channel_id)
        })

        return channels
    }

    addInvitedGroup = async (item: InvitedGroupInfo) => {
        const channelItem = await db.channel.get(item.channel)

        if (channelItem !== undefined) return

        const channel = new Channel()

        channel.uuid = item.channel
        channel.name = item.name
        channel.avatar = item.avatar
        channel.type = ChannelType.GROUP
        channel.lastMessage.state = MessageState.RECEIVED
        channel.lastMessage.text = item.text
        channel.lastMessage.timeHandle = item.timestamp * 1000
        channel.groupType = item.grouptype
        channel.isInviteActionPending = Packet.Values.TRUE
        channel.invitedBy = item.invitedBy

        await db.channel.add(channel)
    }

    addCreatedGroup = async (res: RawPacket) => {

        const channel = new Channel()

        const channelId = res[Packet.Common.CHANNEL]

        channel.uuid = channelId
        channel.name = res[Packet.Group.Keys.NAME]
        channel.avatar = res[Packet.Group.Keys.AVATAR]
        channel.desc = res[Packet.Group.Keys.DESCRIPTION]
        channel.type = ChannelType.GROUP
        channel.lastMessage.state = MessageState.RECEIVED
        channel.lastMessage.text = "Group Created"
        channel.isGroupMember = true
        channel.isGroupAdmin = true
        channel.groupType = res[Packet.Group.Keys.SGROUP_TYPE]
        channel.groupCreateMode = GroupCreateMode.UCG
        channel.memberCount = 1

        const member = new Member()

        member.uuid = Config.shared.myUUID()
        member.name = Config.shared.cached().name
        member.avatar = Config.shared.cached().avatar
        member.grole = GroupRole.ADMIN
        member.urole = Config.shared.cached().authInfo.role
        member.timestamp = res[Packet.Common.TIMESTAMP]

        channel.members.set(member.uuid, member)

        await db.channel.put(channel)
    }

    updateGroupProfileByMe = async (req: RawPacket) => {
        const channelId = req[Packet.Common.CHANNEL]

        const updates: RawPacket = {}

        if (req[Packet.Group.Keys.NAME]) {
            updates.name = req[Packet.Group.Keys.NAME]
        }

        if (req[Packet.Group.Keys.AVATAR]) {
            updates.avatar = req[Packet.Group.Keys.AVATAR]
        }

        if (req[Packet.Group.Keys.DESCRIPTION]) {
            updates.desc = req[Packet.Group.Keys.DESCRIPTION]
        }

        if (Object.keys(updates).length > 0) {
            await db.channel.update(channelId, updates)
        }
    }

    updateGroupProfilePush = async (res: RawPacket) => {
        const channelId = res[Packet.Common.CHANNEL]

        const myUser = await db.user.get(Config.shared.myUUID())
        const dbChannel = await db.channel.get(channelId)

        const updates = {
            name: res[Packet.Group.Keys.NAME],
            avatar: res[Packet.Group.Keys.AVATAR],
            desc: res[Packet.Group.Keys.DESCRIPTION],
            groupType: res[Packet.Group.Keys.SGROUP_TYPE],
            groupCreateMode: res[Packet.Group.Keys.CREATE_MODE],
            isGroupMember: true,
            members: new Map<string, Member>(),
            memberCount: res[Packet.Group.Keys.COUNT],
            isInviteActionPending: Packet.Values.FALSE
        }

        if (dbChannel !== undefined) {

            updates.members = dbChannel.members

            const dbMember = dbChannel.members.get(Config.shared.myUUID())

            if (dbMember === undefined) {
                const member = new Member()

                member.uuid = Config.shared.myUUID()
                member.name = myUser!.name
                member.avatar = myUser!.avatar
                member.urole = myUser!.role
                member.grole = GroupRole.MEMBER
                member.timestamp = res[Packet.Common.TIMESTAMP]

                updates.members.set(member.uuid, member)

            } else if (dbMember.grole === GroupRole.NONE) {
                dbMember.grole = GroupRole.MEMBER

                updates.members.set(dbMember.uuid, dbMember)
            }

            const acceptedInvite = dbChannel.isInviteActionPending === Packet.Values.TRUE

            await db.channel.update(channelId, updates)

            if (acceptedInvite && dbChannel.groupType === GroupType.PRIVATE) {
                const updated = (await db.channel.get(channelId))!
                ChatClient.shared.loadChannel(updated, true)
            }

            return
        }

        const channel = new Channel()
        channel.uuid = channelId
        channel.type = ChannelType.GROUP
        channel.name = updates.name
        channel.avatar = updates.avatar
        channel.desc = updates.desc
        channel.isGroupMember = true
        channel.groupType = updates.groupType
        channel.groupCreateMode = updates.groupCreateMode
        channel.memberCount = updates.memberCount

        const member = new Member()

        member.uuid = Config.shared.myUUID()
        member.name = myUser!.name
        member.avatar = myUser!.avatar
        member.urole = myUser!.role
        member.grole = GroupRole.MEMBER
        member.timestamp = res[Packet.Common.TIMESTAMP]

        channel.members.set(member.uuid, member)

        await db.channel.add(channel)

        if (channel.groupType === GroupType.PRIVATE) {
            ChatClient.shared.loadChannel(channel, true)
        }
    }

    markGroupDeleted = async (channelId: string) => {
        await db.channel.update(channelId, {
            isGroupMember: false,
            "lastMessage.text": "Group Deleted",
            isDeleted: true,
            members: new Map()
        })
    }

    updateMembers = async (
        dbChannel: Channel,
        members: Array<Member>,
        isFromStarting: boolean,
    ): Promise<boolean> => {
        const updates = {
            isGroupAdmin: false,
            isGroupMember: false,
            members: dbChannel.members
        }

        if (isFromStarting) {
            updates.members.clear()
            members.forEach((member) => updates.members.set(member.uuid, member))
        } else {
            members.forEach((member) => {
                const dbMember = updates.members.get(member.uuid)

                if (dbMember === undefined || member.timestamp > dbMember.timestamp) {
                    updates.members.set(member.uuid, member)
                }
            })
        }

        const self = updates.members.get(Config.shared.myUUID())

        if (self) {
            updates.isGroupMember = self.grole !== GroupRole.NONE
            updates.isGroupAdmin = self.grole === GroupRole.ADMIN
        }

        await db.channel.update(dbChannel.uuid, updates)

        return true
    }

    addFetchedGroups = async (
        groups: Array<Channel>,
        timestamp: number,
    ) => {

        const myUser = await db.user.get(Config.shared.myUUID())

        await asyncForEach(groups, async (group) => {

            const member = new Member()

            member.uuid = myUser!.uuid
            member.name = myUser!.name
            member.avatar = myUser!.avatar
            member.urole = myUser!.role
            member.timestamp = timestamp

            if (group.isGroupAdmin) {
                member.grole = GroupRole.ADMIN
            } else if (group.isGroupMember) {
                member.grole = GroupRole.MEMBER
            }

            const dbChannel = await db.channel.get(group.uuid)

            if (dbChannel === undefined) {
                group.members.set(member.uuid, member)

                await db.channel.add(group)
            } else {

                const updates: RawPacket = {
                    name: group.name,
                    avatar: group.avatar,
                    desc: group.desc,
                    isGroupMember: group.isGroupMember,
                    members: dbChannel.members,
                    memberCount: group.memberCount
                }

                updates.members.set(member.uuid, member)

                if (dbChannel.isGroupMember != group.isGroupMember) {
                    // updates.isPendingJoin = false
                    updates.isInviteActionPending = Packet.Values.FALSE
                }

                await db.channel.update(group.uuid, updates)
            }
        })
    }

    cancelInvite = async (channelId: string) => {
        await db.channel.update(channelId, {
            // isPendingJoin: false,
            isInviteActionPending: Packet.Values.FALSE
        })
    }

    addInviteByLinkGroup = async (channel: Channel) => {
        const dbChannel = await db.channel.get(channel.uuid)

        if (dbChannel === undefined) {
            await db.channel.put(channel)
            return
        }

        if (dbChannel.isGroupMember != channel.isGroupMember) {
            dbChannel.name = channel.name
            dbChannel.desc = channel.desc
            dbChannel.avatar = channel.avatar
            dbChannel.isGroupAdmin = channel.isGroupAdmin
            dbChannel.isGroupMember = channel.isGroupMember
            dbChannel.isInviteActionPending = channel.isGroupMember ? Packet.Values.FALSE : Packet.Values.TRUE
            dbChannel.groupJoinToken = channel.groupJoinToken
            dbChannel.memberCount = channel.memberCount
            dbChannel.isDeleted = false
            dbChannel.invitedBy = channel.invitedBy

            await db.channel.put(dbChannel)
        }
    }
}