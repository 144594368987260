import Cookies from "js-cookie";
import UserRole from "../chat/enums/UserRole";
// import { logger } from "../utils/Logger";

export type AuthInfo = {
    uuid?: string,
    role: UserRole
}

export const defaultAuthInfo = (): AuthInfo => {
    return {
        role: UserRole.UNKNOWN
    }
}

export const getAuthUserInfoOrDefault = (): AuthInfo => {
    try {
        return getAuthUserInfo()
    } catch {
        return defaultAuthInfo()
    }
}

export const getAuthUserInfo = (): AuthInfo => {
    const authToken = getAuthToken()

    if (!authToken) {
        // logger.error("auth info missing")
        throw "auth info missing"
    }

    const parsed = JSON.parse(atob(authToken.split(".")[1]));

    const authInfo = defaultAuthInfo()

    if ('student_uuid' in parsed) {
        authInfo.uuid = parsed.student_uuid
        authInfo.role = UserRole.STUDENT
    } else if ('cms_user_uuid' in parsed) {
        authInfo.uuid = parsed.cms_user_uuid
        authInfo.role = UserRole.CARE
    } else if ('teacher_uuid' in parsed) {
        authInfo.uuid = parsed.teacher_uuid
        authInfo.role = UserRole.TEACHER
    } else if ('user_identity' in parsed) {
        authInfo.uuid = parsed.user_identity;
        authInfo.role = parsed.role.toLowerCase();
    } else {
        // logger.error("token parsing failed")
        throw "token parsing failed"
    }

    return authInfo
}

export const getCookie = (cookie_name: string) => {
    return Cookies.get(cookie_name);
};

export const removeCookie = (cookie_name: string) => {
    Cookies.remove(cookie_name,
        { path: '/', domain: '.myyogateacher.com' })
};

export const setCookie = (cookie_name: string, value: string, age = 5184000) => {
    try {
        Cookies.set(cookie_name, value, {
            expires: age,
            path: "/",
            domain: ".myyogateacher.com",
            secure: true,
            SameSite: "Strict",
        });
    } catch (e) {
        // logger.debug(e);
    }
}

export const loadCookie = () => {
    const hostname = window.location.hostname;

    if (hostname.startsWith("teacher")) {
        return getCookie('teacher_token_1')
    } else if (hostname.startsWith("hridaya")) {
        return getCookie('care_chat_token')
    } else {
        return getCookie('student_token') || getCookie('impersonationFromTeacherToken')
    }
}

export const getAuthToken = (): string => {

    if (import.meta.env.SSR) {
        throw "not authenticated"
    }

    const hostname = window.location.hostname;

    if (!hostname.endsWith("myyogateacher.com")) {
        // logger.leaveBreadcrumb("hostname not supported")
        throw "hostname not supported"
    }

    const cookie = loadCookie()

    if (cookie) {
        return cookie
    }

    // logger.leaveBreadcrumb("Cookie is undefined")
    throw "Cookie is undefined"
}