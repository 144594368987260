enum MessageState {
    NONE = -100,

    UPLOAD_FAILED = -100,
    SEND_REJECTED = -99,
    SENDER_DELETED = -98,

    TO_UPLOAD = 1,
    UPLOADING = 2,
    TO_SEND = 3,

    SENT = 100,
    DELIVERED = 101,
    READ = 102,

    RECEIVED = 200,
    DELIVERY_SENT = 201,
    READ_TO_SEND = 203,
    READ_SENT = 204,
}

export default MessageState;