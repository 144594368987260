import React from "react";
import MessageState from "../enums/MessageState"
import MessageType from "../enums/MessageType"
import UserRole from "../enums/UserRole"
import Config from "../models/Config"
import Message, { Media, Mention, Sender } from "../models/Message"
import MessageHandler from "../network/MessageHandler"
import S3Handler from "../network/S3Handler"
import Constants from "../config/Constants";
import { db } from "../database/MytChatDB";
import Linkify from "linkify-react";

export type MessageDisplayItem = {
    sender: Sender,
    text: string,
    mentions: Array<Mention>
    isDeleted?: boolean
}

export default class MessageUtils {

    static fileCacheKey = (message: Message) => {
        return `${message.id}-data`
    }

    static thumbCacheKey = (message: Message) => {
        return `${message.id}-thumb`
    }

    static isUrlValid = (media: Media) => {
        return media.validUpto > Date.now()
    }

    static loadUrl = (message: Message, media: Media): string | undefined => {
        if (message.isDeleted) {
            return media.url
        }

        if (message.state < MessageState.SENT) {
            const thumbFile = MessageHandler.shared.cachedFile(MessageUtils.thumbCacheKey(message))

            if (thumbFile) {
                return URL.createObjectURL(thumbFile);
            }

            const file = MessageHandler.shared.cachedFile(MessageUtils.fileCacheKey(message))
            if (file) {
                return URL.createObjectURL(file);
            }
        }

        if (this.isUrlValid(media)) {
            return media.url
        }

        S3Handler.shared.loadMediaFileUrl(message, media)

        if (MessageUtils.isMediaVideo(media)) {
            return Constants.DEF_VIDEO_THUMB
        }

        // if (MessageUtils.isMediaImage(media)) {
        //     return "https://res.cloudinary.com/dgerdfai4/image/upload/v1687000008/Lv-landingpage/Video_2x.png"
        // }

        return
    }

    static isGroupMessage = (message: Message) => {
        return message.type !== MessageType.P2P
    }

    static isMyMessage = (message: Message) => {
        return message.from === Config.shared.myUUID()
    }

    static isCareMessage = (message: Message) => {
        return message.from === UserRole.CARE
    }

    static isChatMessage = (message: Message) => {
        switch (message.type) {
            case MessageType.P2P:
            case MessageType.GROUP:
                return true
            default:
                return false
        }
    }

    static isStatusMessage = (message: Message) => {
        switch (message.type) {
            case MessageType.GROUP_CREATE:
            case MessageType.JOIN_GROUP:
            case MessageType.LEAVE_GROUP:
            case MessageType.MUC_MEMBER_ROLE_CHANGED:
                return true
            default:
                return false
        }
    }

    static isRejected = (message: Message) => {
        return message.state === MessageState.SEND_REJECTED
    }

    static isPinnedMessage = (message: Message) => {
        return message.isPrivatePinned || message.isPublicPinned
    }

    static isMediaImage(media: Media): boolean {
        return media.mimeType.includes("image");
    }

    static isMediaVideo(media: Media): boolean {
        return media.mimeType.includes("video");
    }

    static isVideoThumbAvailable(media: Media): boolean {
        return MessageUtils.isMediaVideo(media) && !!media.thumbKey && media.thumbKey !== media.dataKey
    }

    static mediaLinkValidity = () => {
        return Date.now() + Constants.OneDayMillis
    }

    static isMediaPDF(media: Media): boolean {
        return media.mimeType.includes("application/pdf");
    }

    static isMediaDoc(media: Media): boolean {
        return media.mimeType.includes("application/doc") || media.mimeType.includes("application/msword") || media.mimeType.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    }

    static getMimeSubType = (message: Message) => {
        if (message.media.length === 0) {
            return "message"
        }

        if (this.isMediaImage(message.media[0])) {
            return 'image';
        }

        if (this.isMediaVideo(message.media[0])) {
            return 'video';
        }

        if (this.isMediaDoc(message.media[0])) {
            return 'document';
        }
        if (this.isMediaPDF(message.media[0])) {
            return 'document';
        }

        return 'message';
    }

    static displayText = (
        message: MessageDisplayItem,
        pinned: boolean = false
    ): string | JSX.Element | JSX.Element[] | (string | JSX.Element)[] => {
        if (message.isDeleted) return Constants.MessageRemoveText
        // let data = message.text
        let parts: Array<JSX.Element | string> = []

        const isMyMessage = message.sender.uuid === Config.shared.myUUID()

        const textColor = pinned || !isMyMessage ? "text-[#7D4AC3]" : "text-white"

        const className = `${isMyMessage ? "underline underline-offset-4" : ""} ${textColor} cursor-pointer`

        if (message.mentions?.length > 0) {
            let startIdx = 0
            // data = ""

            message.mentions.forEach((mention) => {
                const start = message.text.substring(startIdx, mention.start)
                const sub = message.text.substring(mention.start, mention.end)
                startIdx = mention.end

                // data = data + start + `<span>${sub}</span>`
                parts.push(
                    <Linkify key='start'
                        className="whitespace-pre-line font-inter break-words" as={'pre'}
                        options={{
                            defaultProtocol: "https",
                            target: "_blank",
                            truncate: 50,
                            rel: "noopener",
                            ignoreTags: ["script", "style"],
                            className: className
                        }}>
                        {start}
                    </Linkify>)

                parts.push(
                    <span key={`${mention.start}-${mention.end}`} className={className} onClick={() => {
                        if (mention.user === Constants.MENTION_ALL_UUID) {
                            return;
                        }

                        PubSub.publish(Constants.PubSubOpenProfile, {
                            uuid: mention.user,
                            role: mention.role
                        })
                    }}>{sub}
                    </span>
                )
            })

            if (message.text.length > startIdx) {
                const end = message.text.substring(startIdx)

                // data = data + end
                parts.push(
                    <Linkify key='end'
                        className="whitespace-pre-line font-inter break-words" as={'pre'}
                        options={{
                            defaultProtocol: "https",
                            target: "_blank",
                            truncate: 50,
                            rel: "noopener",
                            ignoreTags: ["script", "style"],
                            className: className
                        }}>
                        {end}
                    </Linkify>
                )
            }

            return parts
        }

        return (
            <Linkify key='end' className="whitespace-pre-line font-inter break-words" as={'pre'}
                options={{
                    defaultProtocol: "https",
                    target: "_blank",
                    truncate: 50,
                    rel: "noopener",
                    ignoreTags: ["script", "style"],
                    className: className
                }}>
                {message.text}
            </Linkify>)
        // return <pre className="whitespace-pre-line">{this.processForLinks(data)}</pre>
    }

    static imageFiles = async (channelId: string): Promise<Array<Message>> => {
        let items = new Array<Message>

        let messages = await db.message
            .where({ channel: channelId })
            .and((x) => x.media.length > 0)
            .reverse()
            .sortBy("timeHandle")

        messages.forEach((x) => {
            if (MessageUtils.isMediaImage(x.media[0])) {
                items.push(x)
            }
        })

        return items

    }

    static mediaFiles = async (channelId: string): Promise<Array<Message>> => {
        let items = new Array<Message>

        let messages = await db.message
            .where({ channel: channelId })
            .and((x) => x.media.length > 0)
            .reverse()
            .sortBy("timeHandle")

        messages.forEach((x) => {
            if (MessageUtils.isMediaImage(x.media[0]) || MessageUtils.isMediaVideo(x.media[0])) {
                items.push(x)
            }
        })

        return items

    }

    static documentFiles = async (channelId: string): Promise<Array<Message>> => {
        let items = new Array<Message>

        let messages = await db.message
            .where({ channel: channelId })
            .and((x) => x.media.length > 0)
            .reverse()
            .sortBy("timeHandle")

        messages.forEach((x) => {
            if (MessageUtils.isMediaDoc(x.media[0]) || MessageUtils.isMediaPDF(x.media[0])) {
                items.push(x)
            }
        })

        return items

    }
}